@charset "UTF-8";
.m-l--125 {
  margin-left: -125px;
}
.m-t--125 {
  margin-top: -125px;
}
.m-r--125 {
  margin-right: -125px;
}
.m-b--125 {
  margin-bottom: -125px;
}
.m-l--120 {
  margin-left: -120px;
}
.m-t--120 {
  margin-top: -120px;
}
.m-r--120 {
  margin-right: -120px;
}
.m-b--120 {
  margin-bottom: -120px;
}
.m-l--115 {
  margin-left: -115px;
}
.m-t--115 {
  margin-top: -115px;
}
.m-r--115 {
  margin-right: -115px;
}
.m-b--115 {
  margin-bottom: -115px;
}
.m-l--110 {
  margin-left: -110px;
}
.m-t--110 {
  margin-top: -110px;
}
.m-r--110 {
  margin-right: -110px;
}
.m-b--110 {
  margin-bottom: -110px;
}
.m-l--105 {
  margin-left: -105px;
}
.m-t--105 {
  margin-top: -105px;
}
.m-r--105 {
  margin-right: -105px;
}
.m-b--105 {
  margin-bottom: -105px;
}
.m-l--100 {
  margin-left: -100px;
}
.m-t--100 {
  margin-top: -100px;
}
.m-r--100 {
  margin-right: -100px;
}
.m-b--100 {
  margin-bottom: -100px;
}
.m-l--95 {
  margin-left: -95px;
}
.m-t--95 {
  margin-top: -95px;
}
.m-r--95 {
  margin-right: -95px;
}
.m-b--95 {
  margin-bottom: -95px;
}
.m-l--90 {
  margin-left: -90px;
}
.m-t--90 {
  margin-top: -90px;
}
.m-r--90 {
  margin-right: -90px;
}
.m-b--90 {
  margin-bottom: -90px;
}
.m-l--85 {
  margin-left: -85px;
}
.m-t--85 {
  margin-top: -85px;
}
.m-r--85 {
  margin-right: -85px;
}
.m-b--85 {
  margin-bottom: -85px;
}
.m-l--80 {
  margin-left: -80px;
}
.m-t--80 {
  margin-top: -80px;
}
.m-r--80 {
  margin-right: -80px;
}
.m-b--80 {
  margin-bottom: -80px;
}
.m-l--75 {
  margin-left: -75px;
}
.m-t--75 {
  margin-top: -75px;
}
.m-r--75 {
  margin-right: -75px;
}
.m-b--75 {
  margin-bottom: -75px;
}
.m-l--70 {
  margin-left: -70px;
}
.m-t--70 {
  margin-top: -70px;
}
.m-r--70 {
  margin-right: -70px;
}
.m-b--70 {
  margin-bottom: -70px;
}
.m-l--65 {
  margin-left: -65px;
}
.m-t--65 {
  margin-top: -65px;
}
.m-r--65 {
  margin-right: -65px;
}
.m-b--65 {
  margin-bottom: -65px;
}
.m-l--60 {
  margin-left: -60px;
}
.m-t--60 {
  margin-top: -60px;
}
.m-r--60 {
  margin-right: -60px;
}
.m-b--60 {
  margin-bottom: -60px;
}
.m-l--55 {
  margin-left: -55px;
}
.m-t--55 {
  margin-top: -55px;
}
.m-r--55 {
  margin-right: -55px;
}
.m-b--55 {
  margin-bottom: -55px;
}
.m-l--50 {
  margin-left: -50px;
}
.m-t--50 {
  margin-top: -50px;
}
.m-r--50 {
  margin-right: -50px;
}
.m-b--50 {
  margin-bottom: -50px;
}
.m-l--45 {
  margin-left: -45px;
}
.m-t--45 {
  margin-top: -45px;
}
.m-r--45 {
  margin-right: -45px;
}
.m-b--45 {
  margin-bottom: -45px;
}
.m-l--40 {
  margin-left: -40px;
}
.m-t--40 {
  margin-top: -40px;
}
.m-r--40 {
  margin-right: -40px;
}
.m-b--40 {
  margin-bottom: -40px;
}
.m-l--35 {
  margin-left: -35px;
}
.m-t--35 {
  margin-top: -35px;
}
.m-r--35 {
  margin-right: -35px;
}
.m-b--35 {
  margin-bottom: -35px;
}
.m-l--30 {
  margin-left: -30px;
}
.m-t--30 {
  margin-top: -30px;
}
.m-r--30 {
  margin-right: -30px;
}
.m-b--30 {
  margin-bottom: -30px;
}
.m-l--25 {
  margin-left: -25px;
}
.m-t--25 {
  margin-top: -25px;
}
.m-r--25 {
  margin-right: -25px;
}
.m-b--25 {
  margin-bottom: -25px;
}
.m-l--20 {
  margin-left: -20px;
}
.m-t--20 {
  margin-top: -20px;
}
.m-r--20 {
  margin-right: -20px;
}
.m-b--20 {
  margin-bottom: -20px;
}
.m-l--15 {
  margin-left: -15px;
}
.m-t--15 {
  margin-top: -15px;
}
.m-r--15 {
  margin-right: -15px;
}
.m-b--15 {
  margin-bottom: -15px;
}
.m-l--10 {
  margin-left: -10px;
}
.m-t--10 {
  margin-top: -10px;
}
.m-r--10 {
  margin-right: -10px;
}
.m-b--10 {
  margin-bottom: -10px;
}
.m-l--5 {
  margin-left: -5px;
}
.m-t--5 {
  margin-top: -5px;
}
.m-r--5 {
  margin-right: -5px;
}
.m-b--5 {
  margin-bottom: -5px;
}
.m-l-0 {
  margin-left: 0;
}
.m-t-0 {
  margin-top: 0;
}
.m-r-0 {
  margin-right: 0;
}
.m-b-0 {
  margin-bottom: 0;
}
.m-l-5 {
  margin-left: 5px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-r-5 {
  margin-right: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-r-25 {
  margin-right: 25px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-r-30 {
  margin-right: 30px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-l-35 {
  margin-left: 35px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-r-35 {
  margin-right: 35px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-r-40 {
  margin-right: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-r-45 {
  margin-right: 45px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-l-50 {
  margin-left: 50px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-r-50 {
  margin-right: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-l-55 {
  margin-left: 55px;
}
.m-t-55 {
  margin-top: 55px;
}
.m-r-55 {
  margin-right: 55px;
}
.m-b-55 {
  margin-bottom: 55px;
}
.m-l-60 {
  margin-left: 60px;
}
.m-t-60 {
  margin-top: 60px;
}
.m-r-60 {
  margin-right: 60px;
}
.m-b-60 {
  margin-bottom: 60px;
}
.m-l-65 {
  margin-left: 65px;
}
.m-t-65 {
  margin-top: 65px;
}
.m-r-65 {
  margin-right: 65px;
}
.m-b-65 {
  margin-bottom: 65px;
}
.m-l-70 {
  margin-left: 70px;
}
.m-t-70 {
  margin-top: 70px;
}
.m-r-70 {
  margin-right: 70px;
}
.m-b-70 {
  margin-bottom: 70px;
}
.m-l-75 {
  margin-left: 75px;
}
.m-t-75 {
  margin-top: 75px;
}
.m-r-75 {
  margin-right: 75px;
}
.m-b-75 {
  margin-bottom: 75px;
}
.m-l-80 {
  margin-left: 80px;
}
.m-t-80 {
  margin-top: 80px;
}
.m-r-80 {
  margin-right: 80px;
}
.m-b-80 {
  margin-bottom: 80px;
}
.m-l-85 {
  margin-left: 85px;
}
.m-t-85 {
  margin-top: 85px;
}
.m-r-85 {
  margin-right: 85px;
}
.m-b-85 {
  margin-bottom: 85px;
}
.m-l-90 {
  margin-left: 90px;
}
.m-t-90 {
  margin-top: 90px;
}
.m-r-90 {
  margin-right: 90px;
}
.m-b-90 {
  margin-bottom: 90px;
}
.m-l-95 {
  margin-left: 95px;
}
.m-t-95 {
  margin-top: 95px;
}
.m-r-95 {
  margin-right: 95px;
}
.m-b-95 {
  margin-bottom: 95px;
}
.m-l-100 {
  margin-left: 100px;
}
.m-t-100 {
  margin-top: 100px;
}
.m-r-100 {
  margin-right: 100px;
}
.m-b-100 {
  margin-bottom: 100px;
}
.m-l-105 {
  margin-left: 105px;
}
.m-t-105 {
  margin-top: 105px;
}
.m-r-105 {
  margin-right: 105px;
}
.m-b-105 {
  margin-bottom: 105px;
}
.m-l-110 {
  margin-left: 110px;
}
.m-t-110 {
  margin-top: 110px;
}
.m-r-110 {
  margin-right: 110px;
}
.m-b-110 {
  margin-bottom: 110px;
}
.m-l-115 {
  margin-left: 115px;
}
.m-t-115 {
  margin-top: 115px;
}
.m-r-115 {
  margin-right: 115px;
}
.m-b-115 {
  margin-bottom: 115px;
}
.m-l-120 {
  margin-left: 120px;
}
.m-t-120 {
  margin-top: 120px;
}
.m-r-120 {
  margin-right: 120px;
}
.m-b-120 {
  margin-bottom: 120px;
}
.m-l-125 {
  margin-left: 125px;
}
.m-t-125 {
  margin-top: 125px;
}
.m-r-125 {
  margin-right: 125px;
}
.m-b-125 {
  margin-bottom: 125px;
}
.margin-0 {
  margin: 0;
}
.p-l-0 {
  padding-left: 0;
}
.p-t-0 {
  padding-top: 0;
}
.p-r-0 {
  padding-right: 0;
}
.p-b-0 {
  padding-bottom: 0;
}
.p-l-5 {
  padding-left: 5px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-r-5 {
  padding-right: 5px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-r-15 {
  padding-right: 15px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-r-25 {
  padding-right: 25px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-r-30 {
  padding-right: 30px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-l-35 {
  padding-left: 35px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-r-35 {
  padding-right: 35px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-l-40 {
  padding-left: 40px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-r-40 {
  padding-right: 40px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-t-45 {
  padding-top: 45px;
}
.p-r-45 {
  padding-right: 45px;
}
.p-b-45 {
  padding-bottom: 45px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-r-50 {
  padding-right: 50px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-l-55 {
  padding-left: 55px;
}
.p-t-55 {
  padding-top: 55px;
}
.p-r-55 {
  padding-right: 55px;
}
.p-b-55 {
  padding-bottom: 55px;
}
.p-l-60 {
  padding-left: 60px;
}
.p-t-60 {
  padding-top: 60px;
}
.p-r-60 {
  padding-right: 60px;
}
.p-b-60 {
  padding-bottom: 60px;
}
.p-l-65 {
  padding-left: 65px;
}
.p-t-65 {
  padding-top: 65px;
}
.p-r-65 {
  padding-right: 65px;
}
.p-b-65 {
  padding-bottom: 65px;
}
.p-l-70 {
  padding-left: 70px;
}
.p-t-70 {
  padding-top: 70px;
}
.p-r-70 {
  padding-right: 70px;
}
.p-b-70 {
  padding-bottom: 70px;
}
.p-l-75 {
  padding-left: 75px;
}
.p-t-75 {
  padding-top: 75px;
}
.p-r-75 {
  padding-right: 75px;
}
.p-b-75 {
  padding-bottom: 75px;
}
.p-l-80 {
  padding-left: 80px;
}
.p-t-80 {
  padding-top: 80px;
}
.p-r-80 {
  padding-right: 80px;
}
.p-b-80 {
  padding-bottom: 80px;
}
.p-l-85 {
  padding-left: 85px;
}
.p-t-85 {
  padding-top: 85px;
}
.p-r-85 {
  padding-right: 85px;
}
.p-b-85 {
  padding-bottom: 85px;
}
.p-l-90 {
  padding-left: 90px;
}
.p-t-90 {
  padding-top: 90px;
}
.p-r-90 {
  padding-right: 90px;
}
.p-b-90 {
  padding-bottom: 90px;
}
.p-l-95 {
  padding-left: 95px;
}
.p-t-95 {
  padding-top: 95px;
}
.p-r-95 {
  padding-right: 95px;
}
.p-b-95 {
  padding-bottom: 95px;
}
.p-l-100 {
  padding-left: 100px;
}
.p-t-100 {
  padding-top: 100px;
}
.p-r-100 {
  padding-right: 100px;
}
.p-b-100 {
  padding-bottom: 100px;
}
.p-l-105 {
  padding-left: 105px;
}
.p-t-105 {
  padding-top: 105px;
}
.p-r-105 {
  padding-right: 105px;
}
.p-b-105 {
  padding-bottom: 105px;
}
.p-l-110 {
  padding-left: 110px;
}
.p-t-110 {
  padding-top: 110px;
}
.p-r-110 {
  padding-right: 110px;
}
.p-b-110 {
  padding-bottom: 110px;
}
.p-l-115 {
  padding-left: 115px;
}
.p-t-115 {
  padding-top: 115px;
}
.p-r-115 {
  padding-right: 115px;
}
.p-b-115 {
  padding-bottom: 115px;
}
.p-l-120 {
  padding-left: 120px;
}
.p-t-120 {
  padding-top: 120px;
}
.p-r-120 {
  padding-right: 120px;
}
.p-b-120 {
  padding-bottom: 120px;
}
.p-l-125 {
  padding-left: 125px;
}
.p-t-125 {
  padding-top: 125px;
}
.p-r-125 {
  padding-right: 125px;
}
.p-b-125 {
  padding-bottom: 125px;
}
.margin-0 {
  margin: 0;
}
.padding-0 {
  padding: 0;
}
.margin-5 {
  margin: 5px;
}
.padding-5 {
  padding: 5px;
}
.margin-10 {
  margin: 10px;
}
.padding-10 {
  padding: 10px;
}
.margin-15 {
  margin: 15px;
}
.padding-15 {
  padding: 15px;
}
.margin-20 {
  margin: 20px;
}
.padding-20 {
  padding: 20px;
}
.margin-25 {
  margin: 25px;
}
.padding-25 {
  padding: 25px;
}
.margin-30 {
  margin: 30px;
}
.padding-30 {
  padding: 30px;
}
.margin-35 {
  margin: 35px;
}
.padding-35 {
  padding: 35px;
}
.margin-40 {
  margin: 40px;
}
.padding-40 {
  padding: 40px;
}
.margin-45 {
  margin: 45px;
}
.padding-45 {
  padding: 45px;
}
.margin-50 {
  margin: 50px;
}
.padding-50 {
  padding: 50px;
}
.margin-55 {
  margin: 55px;
}
.padding-55 {
  padding: 55px;
}
.margin-60 {
  margin: 60px;
}
.padding-60 {
  padding: 60px;
}
.margin-65 {
  margin: 65px;
}
.padding-65 {
  padding: 65px;
}
.margin-70 {
  margin: 70px;
}
.padding-70 {
  padding: 70px;
}
.margin-75 {
  margin: 75px;
}
.padding-75 {
  padding: 75px;
}
.margin-80 {
  margin: 80px;
}
.padding-80 {
  padding: 80px;
}
.margin-85 {
  margin: 85px;
}
.padding-85 {
  padding: 85px;
}
.margin-90 {
  margin: 90px;
}
.padding-90 {
  padding: 90px;
}
.margin-95 {
  margin: 95px;
}
.padding-95 {
  padding: 95px;
}
.margin-100 {
  margin: 100px;
}
.padding-100 {
  padding: 100px;
}
.margin-105 {
  margin: 105px;
}
.padding-105 {
  padding: 105px;
}
.margin-110 {
  margin: 110px;
}
.padding-110 {
  padding: 110px;
}
.margin-115 {
  margin: 115px;
}
.padding-115 {
  padding: 115px;
}
.margin-120 {
  margin: 120px;
}
.padding-120 {
  padding: 120px;
}
.margin-125 {
  margin: 125px;
}
.padding-125 {
  padding: 125px;
}
.padding-0 {
  padding: 0;
}
.font-6 {
  font-size: 6px !important;
}
.font-7 {
  font-size: 7px !important;
}
.font-8 {
  font-size: 8px !important;
}
.font-9 {
  font-size: 9px !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-17 {
  font-size: 17px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-19 {
  font-size: 19px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-21 {
  font-size: 21px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-23 {
  font-size: 23px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-25 {
  font-size: 25px !important;
}
.font-26 {
  font-size: 26px !important;
}
.font-27 {
  font-size: 27px !important;
}
.font-28 {
  font-size: 28px !important;
}
.font-29 {
  font-size: 29px !important;
}
.font-30 {
  font-size: 30px !important;
}
.font-31 {
  font-size: 31px !important;
}
.font-32 {
  font-size: 32px !important;
}
.font-33 {
  font-size: 33px !important;
}
.font-34 {
  font-size: 34px !important;
}
.font-35 {
  font-size: 35px !important;
}
.font-36 {
  font-size: 36px !important;
}
.font-37 {
  font-size: 37px !important;
}
.font-38 {
  font-size: 38px !important;
}
.font-39 {
  font-size: 39px !important;
}
.font-40 {
  font-size: 40px !important;
}
.font-41 {
  font-size: 41px !important;
}
.font-42 {
  font-size: 42px !important;
}
.font-43 {
  font-size: 43px !important;
}
.font-44 {
  font-size: 44px !important;
}
.font-45 {
  font-size: 45px !important;
}
.font-46 {
  font-size: 46px !important;
}
.font-47 {
  font-size: 47px !important;
}
.font-48 {
  font-size: 48px !important;
}
.font-49 {
  font-size: 49px !important;
}
.font-50 {
  font-size: 50px !important;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.align-justify {
  text-align: justify;
}
.no-resize {
  resize: none;
}
.font-bold {
  font-weight: bold;
}
.font-italic {
  font-style: italic;
}
.font-underline {
  text-decoration: underline;
}
.font-line-through {
  text-decoration: line-through;
}
.font-overline {
  text-decoration: overline;
}
.bg-red {
  background-color: #f44336 !important;
  color: #fff;
}
.bg-red .content .text,
.bg-red .content .number {
  color: #fff !important;
}
.bg-pink {
  background-color: #e91e63 !important;
  color: #fff;
}
.bg-pink .content .text,
.bg-pink .content .number {
  color: #fff !important;
}
.bg-purple {
  background-color: #9c27b0 !important;
  color: #fff;
}
.bg-purple .content .text,
.bg-purple .content .number {
  color: #fff !important;
}
.bg-deep-purple {
  background-color: #673ab7 !important;
  color: #fff;
}
.bg-deep-purple .content .text,
.bg-deep-purple .content .number {
  color: #fff !important;
}
.bg-indigo {
  background-color: #3f51b5 !important;
  color: #fff;
}
.bg-indigo .content .text,
.bg-indigo .content .number {
  color: #fff !important;
}
.bg-blue {
  background-color: #2196f3 !important;
  color: #fff;
}
.bg-blue .content .text,
.bg-blue .content .number {
  color: #fff !important;
}
.bg-light-blue {
  background-color: #03a9f4 !important;
  color: #fff;
}
.bg-light-blue .content .text,
.bg-light-blue .content .number {
  color: #fff !important;
}
.bg-cyan {
  background-color: #29c0b1 !important;
  color: #fff;
}
.bg-cyan .content .text,
.bg-cyan .content .number {
  color: #fff !important;
}
.bg-teal {
  background-color: #009688 !important;
  color: #fff;
}
.bg-teal .content .text,
.bg-teal .content .number {
  color: #fff !important;
}
.bg-green {
  background-color: #4caf50 !important;
  color: #fff;
}
.bg-green .content .text,
.bg-green .content .number {
  color: #fff !important;
}
.bg-light-green {
  background-color: #8bc34a !important;
  color: #fff;
}
.bg-light-green .content .text,
.bg-light-green .content .number {
  color: #fff !important;
}
.bg-lime {
  background-color: #cddc39 !important;
  color: #fff;
}
.bg-lime .content .text,
.bg-lime .content .number {
  color: #fff !important;
}
.bg-yellow {
  background-color: #ffe821 !important;
  color: #fff;
}
.bg-yellow .content .text,
.bg-yellow .content .number {
  color: #fff !important;
}
.bg-amber {
  background-color: #ffc107 !important;
  color: #fff;
}
.bg-amber .content .text,
.bg-amber .content .number {
  color: #fff !important;
}
.bg-orange {
  background-color: #ff9800 !important;
  color: #fff;
}
.bg-orange .content .text,
.bg-orange .content .number {
  color: #fff !important;
}
.bg-deep-orange {
  background-color: #ff5722 !important;
  color: #fff;
}
.bg-deep-orange .content .text,
.bg-deep-orange .content .number {
  color: #fff !important;
}
.bg-brown {
  background-color: #795548 !important;
  color: #fff;
}
.bg-brown .content .text,
.bg-brown .content .number {
  color: #fff !important;
}
.bg-grey {
  background-color: #9e9e9e !important;
  color: #fff;
}
.bg-grey .content .text,
.bg-grey .content .number {
  color: #fff !important;
}
.bg-blue-grey {
  background-color: #607d8b !important;
  color: #fff;
}
.bg-blue-grey .content .text,
.bg-blue-grey .content .number {
  color: #fff !important;
}
.bg-black {
  background-color: #000 !important;
  color: #fff;
}
.bg-black .content .text,
.bg-black .content .number {
  color: #fff !important;
}
.bg-white {
  background-color: #fff !important;
  color: #fff;
}
.bg-white .content .text,
.bg-white .content .number {
  color: #fff !important;
}
.bg-dark-gray {
  background-color: #888 !important;
  color: #fff;
}
.bg-dark-gray .content .text,
.bg-dark-gray .content .number {
  color: #fff !important;
}
.l-bg-green {
  background: linear-gradient(135deg, #23bdb8 0, #43e794 100%) !important;
  color: #fff;
}
.l-bg-green .content .text,
.l-bg-green .content .number {
  color: #fff !important;
}
.l-bg-orange {
  background: linear-gradient(135deg, #f48665 0, #fda23f 100%) !important;
  color: #fff;
}
.l-bg-orange .content .text,
.l-bg-orange .content .number {
  color: #fff !important;
}
.l-bg-cyan {
  background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
  color: #fff;
}
.l-bg-cyan .content .text,
.l-bg-cyan .content .number {
  color: #fff !important;
}
.l-bg-red {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#a77ffc),
    to(#ff6eac)
  ) !important;
  background: linear-gradient(to right, #a77ffc 0, #ff6eac 100%) !important;
  color: #fff;
}
.l-bg-red .content .text,
.l-bg-red .content .number {
  color: #fff !important;
}
.l-bg-purple {
  background: linear-gradient(135deg, #9a56ff 0, #e36cd9 100%) !important;
  color: #fff;
}
.l-bg-purple .content .text,
.l-bg-purple .content .number {
  color: #fff !important;
}
.l-bg-purple-dark {
  background: linear-gradient(45deg, #a52dd8, #e29bf1) !important;
  color: #fff;
}
.l-bg-purple-dark .content .text,
.l-bg-purple-dark .content .number {
  color: #fff !important;
}
.l-bg-yellow {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f6e384),
    to(#ffd500)
  ) !important;
  background: linear-gradient(to right, #f6e384, #ffd500) !important;
  color: #fff;
}
.l-bg-yellow .content .text,
.l-bg-yellow .content .number {
  color: #fff !important;
}
.col-red {
  color: #f44336 !important;
}
.col-pink {
  color: #e91e63 !important;
}
.col-purple {
  color: #9c27b0 !important;
}
.col-deep-purple {
  color: #673ab7 !important;
}
.col-indigo {
  color: #3f51b5 !important;
}
.col-blue {
  color: #2196f3 !important;
}
.col-light-blue {
  color: #03a9f4 !important;
}
.col-cyan {
  color: #29c0b1 !important;
}
.col-teal {
  color: #009688 !important;
}
.col-green {
  color: #4caf50 !important;
}
.col-light-green {
  color: #8bc34a !important;
}
.col-lime {
  color: #cddc39 !important;
}
.col-yellow {
  color: #ffe821 !important;
}
.col-amber {
  color: #ffc107 !important;
}
.col-orange {
  color: #ff9800 !important;
}
.col-deep-orange {
  color: #ff5722 !important;
}
.col-brown {
  color: #795548 !important;
}
.col-grey {
  color: #9e9e9e !important;
}
.col-blue-grey {
  color: #607d8b !important;
}
.col-black {
  color: #000 !important;
}
.col-white {
  color: #fff !important;
}
.col-dark-gray {
  color: #888 !important;
}
.width-per-0 {
  width: 0;
}
.width-per-1 {
  width: 1%;
}
.width-per-2 {
  width: 2%;
}
.width-per-3 {
  width: 3%;
}
.width-per-4 {
  width: 4%;
}
.width-per-5 {
  width: 5%;
}
.width-per-6 {
  width: 6%;
}
.width-per-7 {
  width: 7%;
}
.width-per-8 {
  width: 8%;
}
.width-per-9 {
  width: 9%;
}
.width-per-10 {
  width: 10%;
}
.width-per-11 {
  width: 11%;
}
.width-per-12 {
  width: 12%;
}
.width-per-13 {
  width: 13%;
}
.width-per-14 {
  width: 14%;
}
.width-per-15 {
  width: 15%;
}
.width-per-16 {
  width: 16%;
}
.width-per-17 {
  width: 17%;
}
.width-per-18 {
  width: 18%;
}
.width-per-19 {
  width: 19%;
}
.width-per-20 {
  width: 20%;
}
.width-per-21 {
  width: 21%;
}
.width-per-22 {
  width: 22%;
}
.width-per-23 {
  width: 23%;
}
.width-per-24 {
  width: 24%;
}
.width-per-25 {
  width: 25%;
}
.width-per-26 {
  width: 26%;
}
.width-per-27 {
  width: 27%;
}
.width-per-28 {
  width: 28%;
}
.width-per-29 {
  width: 29%;
}
.width-per-30 {
  width: 30%;
}
.width-per-31 {
  width: 31%;
}
.width-per-32 {
  width: 32%;
}
.width-per-33 {
  width: 33%;
}
.width-per-34 {
  width: 34%;
}
.width-per-35 {
  width: 35%;
}
.width-per-36 {
  width: 36%;
}
.width-per-37 {
  width: 37%;
}
.width-per-38 {
  width: 38%;
}
.width-per-39 {
  width: 39%;
}
.width-per-40 {
  width: 40%;
}
.width-per-41 {
  width: 41%;
}
.width-per-42 {
  width: 42%;
}
.width-per-43 {
  width: 43%;
}
.width-per-44 {
  width: 44%;
}
.width-per-45 {
  width: 45%;
}
.width-per-46 {
  width: 46%;
}
.width-per-47 {
  width: 47%;
}
.width-per-48 {
  width: 48%;
}
.width-per-49 {
  width: 49%;
}
.width-per-50 {
  width: 50%;
}
.width-per-51 {
  width: 51%;
}
.width-per-52 {
  width: 52%;
}
.width-per-53 {
  width: 53%;
}
.width-per-54 {
  width: 54%;
}
.width-per-55 {
  width: 55%;
}
.width-per-56 {
  width: 56%;
}
.width-per-57 {
  width: 57%;
}
.width-per-58 {
  width: 58%;
}
.width-per-59 {
  width: 59%;
}
.width-per-60 {
  width: 60%;
}
.width-per-61 {
  width: 61%;
}
.width-per-62 {
  width: 62%;
}
.width-per-63 {
  width: 63%;
}
.width-per-64 {
  width: 64%;
}
.width-per-65 {
  width: 65%;
}
.width-per-66 {
  width: 66%;
}
.width-per-67 {
  width: 67%;
}
.width-per-68 {
  width: 68%;
}
.width-per-69 {
  width: 69%;
}
.width-per-70 {
  width: 70%;
}
.width-per-71 {
  width: 71%;
}
.width-per-72 {
  width: 72%;
}
.width-per-73 {
  width: 73%;
}
.width-per-74 {
  width: 74%;
}
.width-per-75 {
  width: 75%;
}
.width-per-76 {
  width: 76%;
}
.width-per-77 {
  width: 77%;
}
.width-per-78 {
  width: 78%;
}
.width-per-79 {
  width: 79%;
}
.width-per-80 {
  width: 80%;
}
.width-per-81 {
  width: 81%;
}
.width-per-82 {
  width: 82%;
}
.width-per-83 {
  width: 83%;
}
.width-per-84 {
  width: 84%;
}
.width-per-85 {
  width: 85%;
}
.width-per-86 {
  width: 86%;
}
.width-per-87 {
  width: 87%;
}
.width-per-88 {
  width: 88%;
}
.width-per-89 {
  width: 89%;
}
.width-per-90 {
  width: 90%;
}
.width-per-91 {
  width: 91%;
}
.width-per-92 {
  width: 92%;
}
.width-per-93 {
  width: 93%;
}
.width-per-94 {
  width: 94%;
}
.width-per-95 {
  width: 95%;
}
.width-per-96 {
  width: 96%;
}
.width-per-97 {
  width: 97%;
}
.width-per-98 {
  width: 98%;
}
.width-per-99 {
  width: 99%;
}
.width-per-100 {
  width: 100%;
}
.btn:focus,
.btn:active,
.btn:active:focus,
.custom-select:focus,
.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0;
}
a {
  color: #6777ef;
  font-weight: 500;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
a:not(.btn-social-icon):not(.btn-social):not(.page-link) .ion,
a:not(.btn-social-icon):not(.btn-social):not(.page-link) .fas,
a:not(.btn-social-icon):not(.btn-social):not(.page-link) .far,
a:not(.btn-social-icon):not(.btn-social):not(.page-link) .fal,
a:not(.btn-social-icon):not(.btn-social):not(.page-link) .fab {
  margin-left: 4px;
}
.bg-primary {
  background-color: #6777ef !important;
}
.bg-secondary {
  background-color: #cdd3d8 !important;
}
.bg-success {
  background-color: #54ca68 !important;
}
.bg-info {
  background-color: #3abaf4 !important;
}
.bg-warning {
  background-color: #ffa426 !important;
}
.bg-danger {
  background-color: #fc544b !important;
}
.bg-light {
  background-color: #e3eaef !important;
}
.bg-dark {
  background-color: #191d21 !important;
}
.text-primary,
.text-primary-all *,
.text-primary-all *:before,
.text-primary-all *:after {
  color: #6777ef !important;
}
.text-secondary,
.text-secondary-all *,
.text-secondary-all *:before,
.text-secondary-all *:after {
  color: #cdd3d8 !important;
}
.text-success,
.text-success-all *,
.text-success-all *:before,
.text-success-all *:after {
  color: #54ca68 !important;
}
.text-info,
.text-info-all *,
.text-info-all *:before,
.text-info-all *:after {
  color: #3abaf4 !important;
}
.text-warning,
.text-warning-all *,
.text-warning-all *:before,
.text-warning-all *:after {
  color: #ffa426 !important;
}
.text-danger,
.text-danger-all *,
.text-danger-all *:before,
.text-danger-all *:after {
  color: #fc544b !important;
}
.text-light,
.text-light-all *,
.text-light-all *:before,
.text-light-all *:after {
  color: #e3eaef !important;
}
.text-white,
.text-white-all *,
.text-white-all *:before,
.text-white-all *:after {
  color: #fff !important;
}
.text-dark,
.text-dark-all *,
.text-dark-all *:before,
.text-dark-all *:after {
  color: #191d21 !important;
}
.font-weight-normal {
  font-weight: 500 !important;
}
.lead {
  line-height: 34px;
}
@media (max-width: 575.98px) {
  .lead {
    font-size: 17px;
    line-height: 30px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
p,
ul:not(.list-unstyled),
ol {
  line-height: 28px;
}
.shadow {
  -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}
.text-muted {
  color: #98a6ad !important;
}
.form-control,
.input-group-text,
.custom-select,
.custom-file-label {
  background-color: #fdfdff;
  border-color: #e4e6fc;
}
.form-control:focus,
.input-group-text:focus,
.custom-select:focus,
.custom-file-label:focus {
  background-color: #fefeff;
  border-color: #95a0f4;
}
.input-group-text,
select.form-control:not([size]):not([multiple]),
.form-control:not(.form-control-sm):not(.form-control-lg) {
  font-size: 14px;
  padding: 10px 15px;
  height: 42px;
}
textarea.form-control {
  height: 64px !important;
}
.custom-control {
  line-height: 1.6rem;
}
.custom-file,
.custom-file-label,
.custom-select,
.custom-file-label:after,
.form-control[type="color"],
select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 6px);
}
.form-control.creditcard {
  background-position: 98%;
  background-repeat: no-repeat;
  background-size: 40px;
  padding-right: 60px;
}
.form-group {
  margin-bottom: 25px;
}
.form-group .control-label,
.form-group > label {
  font-weight: 600;
  color: #34395e;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.form-group.floating-addon {
  position: relative;
}
.form-group.floating-addon .input-group-prepend {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 5;
}
.form-group.floating-addon:not(.floating-addon-not-append) .input-group-append {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  left: initial;
  right: 0;
}
.form-group.floating-addon .input-group-prepend .input-group-text,
.form-group.floating-addon .input-group-append .input-group-text {
  border-color: transparent;
  background-color: transparent;
  font-size: 20px;
}
.form-group.floating-addon .form-control {
  border-radius: 3px;
  padding-left: 40px;
}
.form-group.floating-addon .form-control + .form-control {
  border-radius: 0 3px 3px 0;
  padding-left: 15px;
}
.input-group-append [class*="btn-outline-"] {
  background-color: #fdfdff;
}
.form-text {
  font-size: 12px;
  line-height: 22px;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6777ef !important;
}
.custom-file-label {
  line-height: 2.2;
}
.custom-file-label:after {
  height: calc(2.25rem + 4px);
  line-height: 2.2;
  border-color: transparent;
}
.custom-file-label:focus,
.custom-file-label:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}
.custom-file-input:focus + .custom-file-label {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #6777ef;
}
.custom-file-input:focus + .custom-file-label:after {
  border-color: transparent;
}
.selectgroup {
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: inline-flex;
}
.selectgroup-item {
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  position: relative;
}
.selectgroup-item + .selectgroup-item {
  margin-left: -1px;
}
.selectgroup-item:not(:first-child) .selectgroup-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.selectgroup-item:not(:last-child) .selectgroup-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.selectgroup-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}
.selectgroup-button {
  background-color: #fdfdff;
  border-color: #e4e6fc;
  border-width: 1px;
  border-style: solid;
  display: block;
  text-align: center;
  padding: 0 1rem;
  height: 35px;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 13px;
  min-width: 2.375rem;
  line-height: 36px;
}
.selectgroup-button-icon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.selectgroup-button-icon i {
  font-size: 14px;
}
.selectgroup-input:focus + .selectgroup-button,
.selectgroup-input:checked + .selectgroup-button {
  background-color: #6777ef;
  color: #fff;
  z-index: 1;
}
.selectgroup-pills {
  display: block;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}
.selectgroup-pills .selectgroup-item {
  margin-right: 0.5rem;
  -ms-flex-positive: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
}
.selectgroup-pills .selectgroup-button {
  border-radius: 50px !important;
}
.custom-switch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: inline-flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 0;
}
.custom-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-switches-stacked {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
.custom-switches-stacked .custom-switch {
  margin-bottom: 0.5rem;
}
.custom-switch-indicator {
  display: inline-block;
  height: 1.25rem;
  width: 2.25rem;
  background: #e9ecef;
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid rgba(0, 40, 100, 0.12);
  -webkit-transition:
    0.3s border-color,
    0.3s background-color;
  transition:
    0.3s border-color,
    0.3s background-color;
}
.custom-switch-indicator:before {
  content: "";
  position: absolute;
  height: calc(1.25rem - 4px);
  width: calc(1.25rem - 4px);
  top: 1px;
  left: 1px;
  background: #fff;
  border-radius: 50%;
  -webkit-transition: 0.3s left;
  transition: 0.3s left;
}
.custom-switch-input:checked ~ .custom-switch-indicator {
  background: #6777ef;
}
.custom-switch-input:checked ~ .custom-switch-indicator:before {
  left: calc(1rem + 1px);
}
.custom-switch-input:focus ~ .custom-switch-indicator {
  border-color: #6777ef;
}
.custom-switch-description {
  margin-left: 0.5rem;
  color: #6e7687;
  -webkit-transition: 0.3s color;
  transition: 0.3s color;
}
.custom-switch-input:checked ~ .custom-switch-description {
  color: #495057;
}
.imagecheck {
  margin: 0;
  position: relative;
  cursor: pointer;
}
.imagecheck-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.imagecheck-figure {
  background-color: #fdfdff;
  border-color: #e4e6fc;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  margin: 0;
  position: relative;
}
.imagecheck-input:focus ~ .imagecheck-figure {
  border-color: #6777ef;
}
.imagecheck-input:checked ~ .imagecheck-figure {
  border-color: rgba(0, 40, 100, 0.24);
}
.imagecheck-figure:before {
  content: "";
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #6777ef
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
    no-repeat center center/50% 50%;
  color: #fff;
  z-index: 1;
  border-radius: 3px;
  opacity: 0;
  -webkit-transition: 0.3s opacity;
  transition: 0.3s opacity;
}
.imagecheck-input:checked ~ .imagecheck-figure:before {
  opacity: 1;
}
.imagecheck-image {
  max-width: 100%;
  opacity: 0.64;
  -webkit-transition: 0.3s opacity;
  transition: 0.3s opacity;
}
.imagecheck-image:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.imagecheck-image:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.imagecheck:hover .imagecheck-image {
  opacity: 1;
}
.imagecheck-input:focus ~ .imagecheck-figure .imagecheck-image,
.imagecheck-input:checked ~ .imagecheck-figure .imagecheck-image {
  opacity: 1;
}
.imagecheck-caption {
  text-align: center;
  padding: 0.25rem 0.25rem;
  color: #9aa0ac;
  font-size: 0.875rem;
  -webkit-transition: 0.3s color;
  transition: 0.3s color;
}
.imagecheck:hover .imagecheck-caption {
  color: #495057;
}
.imagecheck-input:focus ~ .imagecheck-figure .imagecheck-caption,
.imagecheck-input:checked ~ .imagecheck-figure .imagecheck-caption {
  color: #495057;
}
.colorinput {
  margin: 0;
  position: relative;
  cursor: pointer;
}
.colorinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.colorinput-color {
  background-color: #fdfdff;
  border-color: #e4e6fc;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 3px;
  color: #fff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.colorinput-color:before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  height: 1.25rem;
  width: 1.25rem;
  -webkit-transition: 0.3s opacity;
  transition: 0.3s opacity;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
    no-repeat center center/50% 50%;
}
.colorinput-input:checked ~ .colorinput-color:before {
  opacity: 1;
}
.list-unstyled-border li {
  border-bottom: 1px solid #f9f9f9;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.list-unstyled-border li .custom-checkbox {
  margin-right: 15px;
}
.list-unstyled-border li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.list-unstyled-noborder li:last-child {
  border-bottom: 0;
}
.list-group-item.active {
  background-color: #6777ef;
}
.list-group-item.disabled {
  color: #c9d7e0;
}
.list-group-item-primary {
  background-color: #6777ef;
  color: #fff;
}
.list-group-item-secondary {
  background-color: #cdd3d8;
  color: #fff;
}
.list-group-item-success {
  background-color: #54ca68;
  color: #fff;
}
.list-group-item-danger {
  background-color: #fc544b;
  color: #fff;
}
.list-group-item-warning {
  background-color: #ffa426;
  color: #fff;
}
.list-group-item-info {
  background-color: #3abaf4;
  color: #fff;
}
.list-group-item-light {
  background-color: #e3eaef;
  color: #191d21;
}
.list-group-item-dark {
  background-color: #191d21;
  color: #fff;
}
.alert {
  color: #fff;
  border: 0;
  padding: 15px 20px;
}
.alert .alert-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}
.alert code {
  background-color: #fff;
  border-radius: 3px;
  padding: 1px 4px;
}
.alert p {
  margin-bottom: 0;
}
.alert.alert-has-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.alert.alert-has-icon .alert-icon {
  margin-top: 4px;
  width: 30px;
}
.alert.alert-has-icon .alert-icon .ion,
.alert.alert-has-icon .alert-icon .fas,
.alert.alert-has-icon .alert-icon .far,
.alert.alert-has-icon .alert-icon .fab,
.alert.alert-has-icon .alert-icon .fal {
  font-size: 20px;
}
.alert.alert-has-icon .alert-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.alert:not(.alert-light) a {
  color: #fff;
}
.alert.alert-primary {
  background-color: #6777ef;
}
.alert.alert-secondary {
  background-color: #cdd3d8;
}
.alert.alert-success {
  background-color: #54ca68;
}
.alert.alert-info {
  background-color: #3abaf4;
}
.alert.alert-warning {
  background-color: #ffa426;
}
.alert.alert-danger {
  background-color: #fc544b;
}
.alert.alert-light {
  background-color: #e3eaef;
  color: #191d21;
}
.alert.alert-dark {
  background-color: #191d21;
}
.card {
  background-color: #fff;
  border-radius: 10px;
  border: 0;
  position: relative;
  margin-bottom: 30px;
  -webkit-box-shadow:
    0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
  box-shadow:
    0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}
.card .card-header,
.card .card-body,
.card .card-footer {
  background-color: transparent;
  padding: 20px 25px;
}
.card .navbar {
  position: static;
}
.card .card-body {
  padding-top: 20px;
  padding-bottom: 20px;
}
.card .card-body .section-title {
  margin: 30px 0 10px 0;
  font-size: 16px;
}
.card .card-body .section-title:before {
  margin-top: 8px;
}
.card .card-body .section-title + .section-lead {
  margin-top: -5px;
}
.card .card-body p {
  font-weight: 500;
}
.card .card-header {
  border-bottom-color: #f9f9f9;
  line-height: 30px;
  -ms-grid-row-align: center;
  -ms-flex-item-align: center;
  align-self: center;
  width: 100%;
  padding: 10px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.card .card-header .btn {
  margin-top: 1px;
  padding: 2px 15px;
}
.card .card-header .btn:not(.note-btn) {
  border-radius: 30px;
}
.card .card-header .btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.card .card-header .form-control {
  height: 31px;
  font-size: 13px;
  border-radius: 30px;
}
.card .card-header .form-control + .input-group-btn .btn {
  margin-top: -1px;
}
.card .card-header h4 {
  font-size: 16px;
  line-height: 28px;
  padding-right: 10px;
  margin-bottom: 0;
}
.card .card-header h4 + .card-header-action,
.card .card-header h4 + .card-header-form {
  margin-left: auto;
}
.card .card-header h4 + .card-header-action .btn,
.card .card-header h4 + .card-header-form .btn {
  font-size: 12px;
  border-radius: 30px !important;
  padding-left: 13px !important;
  padding-right: 13px !important;
}
.card .card-header h4 + .card-header-action .btn.active,
.card .card-header h4 + .card-header-form .btn.active {
  -webkit-box-shadow: 0 2px 6px #acb5f6;
  box-shadow: 0 2px 6px #acb5f6;
  background-color: #6777ef;
  color: #fff;
}
.card .card-header h4 + .card-header-action .dropdown,
.card .card-header h4 + .card-header-form .dropdown {
  display: inline;
}
.card .card-header h4 + .card-header-action .btn-group .btn,
.card .card-header h4 + .card-header-form .btn-group .btn {
  border-radius: 0 !important;
}
.card .card-header h4 + .card-header-action .btn-group .btn:first-child,
.card .card-header h4 + .card-header-form .btn-group .btn:first-child {
  border-radius: 30px 0 0 30px !important;
}
.card .card-header h4 + .card-header-action .btn-group .btn:last-child,
.card .card-header h4 + .card-header-form .btn-group .btn:last-child {
  border-radius: 0 30px 30px 0 !important;
}
.card .card-header h4 + .card-header-action .input-group .form-control,
.card .card-header h4 + .card-header-form .input-group .form-control {
  border-radius: 30px 0 0 30px !important;
}
.card
  .card-header
  h4
  + .card-header-action
  .input-group
  .form-control
  + .input-group-btn
  .btn,
.card
  .card-header
  h4
  + .card-header-form
  .input-group
  .form-control
  + .input-group-btn
  .btn {
  border-radius: 0 30px 30px 0 !important;
}
.card
  .card-header
  h4
  + .card-header-action
  .input-group
  .input-group-btn
  + .form-control,
.card
  .card-header
  h4
  + .card-header-form
  .input-group
  .input-group-btn
  + .form-control {
  border-radius: 0 30px 30px 0 !important;
}
.card .card-header h4 + .card-header-action .input-group .input-group-btn .btn,
.card .card-header h4 + .card-header-form .input-group .input-group-btn .btn {
  margin-top: -1px;
  border-radius: 30px 0 0 30px !important;
}
.card .card-footer {
  background-color: transparent;
  border: 0;
}
.card.card-mt {
  margin-top: 30px;
}
.card.card-progress:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 99;
  z-index: 99;
}
.card.card-progress .card-progress-dismiss {
  position: absolute;
  top: 66%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;
  color: #fff !important;
  padding: 5px 13px;
}
.card.card-progress.remove-spinner .card-progress-dismiss {
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.card.card-progress:not(.remove-spinner):after {
  background-image: url("../img/spinner.svg");
  background-size: 80px;
  background-repeat: no-repeat;
  background-position: center;
}
.card.card-primary {
  border-top: 2px solid #6777ef;
}
.card.card-secondary {
  border-top: 2px solid #34395e;
}
.card.card-success {
  border-top: 2px solid #54ca68;
}
.card.card-danger {
  border-top: 2px solid #fc544b;
}
.card.card-warning {
  border-top: 2px solid #ffa426;
}
.card.card-info {
  border-top: 2px solid #3abaf4;
}
.card.card-dark {
  border-top: 2px solid #191d21;
}
.card.card-hero .card-header {
  padding: 40px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#6777ef),
    to(#95a0f4)
  );
  background-image: linear-gradient(to bottom, #6777ef, #95a0f4);
  color: #fff;
  overflow: hidden;
  height: auto;
  min-height: auto;
  display: block;
}
.card.card-hero .card-header h4 {
  font-size: 40px;
  line-height: 1;
}
.card.card-hero .card-header .card-description {
  margin-top: 5px;
  font-size: 16px;
}
.card.card-hero .card-header .card-icon {
  float: right;
  color: #8c98f3;
  margin: -60px;
}
.card.card-hero .card-header .card-icon .ion,
.card.card-hero .card-header .card-icon .fas,
.card.card-hero .card-header .card-icon .far,
.card.card-hero .card-header .card-icon .fab,
.card.card-hero .card-header .card-icon .fal {
  font-size: 140px;
}
.card.card-statistic-1 .card-header,
.card.card-statistic-2 .card-header {
  border-color: transparent;
  padding-bottom: 0;
  height: auto;
  min-height: auto;
  display: block;
}
.card.card-statistic-1 .card-icon {
  width: 30px;
  height: 30px;
  margin: 10px 0 0 20px;
  border-radius: 3px;
  line-height: 78px;
  text-align: center;
  float: left;
  font-size: 30px;
}
.card.card-statistic-1 .card-header h4,
.card.card-statistic-2 .card-header h4 {
  line-height: 1.2;
  color: #98a6ad;
}
.card.card-statistic-1 .card-body,
.card.card-statistic-2 .card-body {
  padding-top: 0;
}
.card.card-statistic-1 .card-body,
.card.card-statistic-2 .card-body {
  font-size: 26px;
  font-weight: 700;
  color: #34395e;
  padding-bottom: 0;
}
.card.card-statistic-1,
.card.card-statistic-2 {
  display: inline-block;
  width: 100%;
}
.card.card-statistic-1 .card-icon,
.card.card-statistic-2 .card-icon {
  width: 80px;
  height: 80px;
  margin: 10px;
  border-radius: 3px;
  line-height: 94px;
  text-align: center;
  float: left;
  border-radius: 50px;
  margin-right: 15px;
}
.card.card-statistic-1 .card-icon .ion,
.card.card-statistic-1 .card-icon .fas,
.card.card-statistic-1 .card-icon .far,
.card.card-statistic-1 .card-icon .fab,
.card.card-statistic-1 .card-icon .fal,
.card.card-statistic-2 .card-icon .ion,
.card.card-statistic-2 .card-icon .fas,
.card.card-statistic-2 .card-icon .far,
.card.card-statistic-2 .card-icon .fab,
.card.card-statistic-2 .card-icon .fal {
  font-size: 22px;
  color: #fff;
}
.card.card-statistic-1 .card-icon {
  line-height: 90px;
}
.card.card-statistic-2 .card-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 22px;
  margin: 25px;
  -webkit-box-shadow: 5px 3px 10px 0 rgba(21, 15, 15, 0.3);
  box-shadow: 5px 3px 10px 0 rgba(21, 15, 15, 0.3);
  border-radius: 10px;
  background: #6777ef;
}
.card.card-statistic-1 .card-header,
.card.card-statistic-2 .card-header {
  padding-bottom: 0;
  padding-top: 25px;
}
.card.card-statistic-2 .card-body {
  padding-top: 20px;
}
.card.card-statistic-2 .card-header + .card-body,
.card.card-statistic-2 .card-body + .card-header {
  padding-top: 0;
}
.card.card-statistic-1 .card-header h4,
.card.card-statistic-2 .card-header h4 {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.5px;
}
.card.card-statistic-1 .card-header h4 {
  margin-bottom: 0;
}
.card.card-statistic-2 .card-header h4 {
  text-transform: none;
  margin-bottom: 0;
}
.card.card-statistic-1 .card-body {
  font-size: 20px;
}
.card.card-statistic-2 .card-chart {
  padding-top: 20px;
  margin-left: -9px;
  margin-right: -1px;
  margin-bottom: -15px;
}
.card.card-statistic-2 .card-chart canvas {
  height: 90px !important;
}
.card .card-stats {
  width: 100%;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: -6px;
}
.card .card-stats .card-stats-title {
  padding: 15px 25px;
  background-color: #fff;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
}
.card .card-stats .card-stats-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.card .card-stats .card-stats-item {
  width: calc(100% / 3);
  text-align: center;
  padding: 5px 20px;
}
.card .card-stats .card-stats-item .card-stats-item-label {
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-top: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.card .card-stats .card-stats-item .card-stats-item-count {
  line-height: 1;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
}
.card.card-large-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.card.card-large-icons .card-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 150px;
  border-radius: 3px 0 0 3px;
}
.card.card-large-icons .card-icon .ion,
.card.card-large-icons .card-icon .fas,
.card.card-large-icons .card-icon .far,
.card.card-large-icons .card-icon .fab,
.card.card-large-icons .card-icon .fal {
  font-size: 60px;
}
.card.card-large-icons .card-body {
  padding: 25px 30px;
}
.card.card-large-icons .card-body h4 {
  font-size: 18px;
}
.card.card-large-icons .card-body p {
  opacity: 0.6;
  font-weight: 500;
}
.card.card-large-icons .card-body a.card-cta {
  text-decoration: none;
}
.card.card-large-icons .card-body a.card-cta i {
  margin-left: 7px;
}
.card.bg-primary,
.card.bg-danger,
.card.bg-success,
.card.bg-info,
.card.bg-dark,
.card.bg-warning {
  color: #fff;
}
.card.bg-primary .card-header,
.card.bg-danger .card-header,
.card.bg-success .card-header,
.card.bg-info .card-header,
.card.bg-dark .card-header,
.card.bg-warning .card-header {
  color: #fff;
  opacity: 0.9;
}
.card .card-type-3 .card-circle {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
  border-radius: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  -webkit-box-shadow:
    0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
  box-shadow:
    0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
}
.card .card-type-3 .card-circle i {
  font-size: 15px;
}
@media (max-width: 575.98px) {
  .card.card-large-icons {
    display: inline-block;
  }
  .card.card-large-icons .card-icon {
    width: 100%;
    height: 200px;
  }
}
@media (max-width: 767.98px) {
  .card .card-header {
    height: auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .card .card-header h4 + .card-header-action,
  .card .card-header h4 + .card-header-form {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    width: 100%;
    margin-top: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .card .card-stats .card-stats-items {
    height: 49px;
  }
  .card .card-stats .card-stats-items .card-stats-item {
    padding: 5px 7px;
  }
  .card .card-stats .card-stats-items .card-stats-item .card-stats-item-count {
    font-size: 16px;
  }
  .card.card-sm-6 .card-chart canvas {
    height: 85px !important;
  }
  .card.card-hero .card-header {
    padding: 25px;
  }
}
.table td,
.table:not(.table-bordered) th {
  border-top: 0;
}
.table:not(.table-sm):not(.table-md):not(.dataTable) td,
.table:not(.table-sm):not(.table-md):not(.dataTable) th {
  padding: 0 10px;
  height: 60px;
  vertical-align: middle;
}
.table:not(.table-sm) thead th {
  border-bottom: 0;
  background-color: rgba(0, 0, 0, 0.04);
  color: #666;
  padding-top: 15px;
  padding-bottom: 15px;
}
.table.table-md th,
.table.table-md td {
  padding: 10px 15px;
}
.table.table-bordered td,
.table.table-bordered th {
  border-color: #f6f6f6;
}
.table .team-member {
  position: relative;
  width: 30px;
  white-space: nowrap;
  border-radius: 1000px;
  vertical-align: bottom;
  display: inline-block;
}
.table .team-member img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border: 0;
  border-radius: 1000px;
}
.table .team-member-sm {
  width: 32px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.table .team-member-sm:hover {
  webkit-transform: translateY(-4px) scale(1.02);
  -webkit-transform: translateY(-4px) scale(1.02);
  transform: translateY(-4px) scale(1.02);
  -webkit-box-shadow: 0 14px 24px rgba(75, 70, 124, 0.2);
  box-shadow: 0 14px 24px rgba(75, 70, 124, 0.2);
  z-index: 999;
}
.table .order-list li img {
  border: 2px solid #fff;
  -webkit-box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
}
.table .order-list li + li {
  margin-left: -14px;
  background: transparent;
}
.table .order-list li .badge {
  background: rgba(228, 222, 222, 0.8);
  color: #6b6f82;
  margin-bottom: 6px;
}
.table-links {
  color: #34395e;
  font-size: 12px;
  margin-top: 5px;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.table-links a {
  color: #666;
}
table tr:hover .table-links {
  opacity: 1;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
@media (max-width: 575.98px) {
  .table-responsive table {
    min-width: 800px;
  }
}
.tooltip {
  font-size: 12px;
}
.tooltip-inner {
  padding: 7px 13px;
}
.modal-header,
.modal-body,
.modal-footer {
  padding: 25px;
}
.modal-body {
  padding-top: 15px;
}
.modal-footer {
  padding-top: 15px;
  padding-bottom: 15px;
}
.modal-header {
  border-bottom: 0;
  padding-bottom: 5px;
}
.modal-header h5 {
  font-size: 18px;
}
.modal-footer {
  border-top: 0;
  border-radius: 0 0 3px 3px;
}
.modal-content {
  max-width: 100%;
  border: 0;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}
.modal.show .modal-content {
  -webkit-box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
.modal-progress .modal-content {
  position: relative;
}
.modal-progress .modal-content:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
  background-image: url("../img/spinner.svg");
  background-size: 80px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
}
.modal-part {
  display: none;
}
.nav-tabs .nav-item .nav-link {
  color: #6777ef;
}
.nav-tabs .nav-item .nav-link.active {
  color: #000;
}
.tab-content > .tab-pane {
  padding: 10px 0;
  line-height: 24px;
}
.tab-bordered .tab-pane {
  padding: 15px;
  border: 1px solid #ededed;
  margin-top: -1px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #6777ef;
}
.nav-pills .nav-item .nav-link {
  color: #6777ef;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.nav-pills .nav-item .nav-link:hover {
  background-color: #f6f7fe;
}
.nav-pills .nav-item .nav-link.active {
  -webkit-box-shadow: 0 2px 6px #acb5f6;
  box-shadow: 0 2px 6px #acb5f6;
  color: #fff;
  background-color: #6777ef;
}
.nav-pills .nav-item .nav-link .badge {
  padding: 5px 8px;
  margin-left: 5px;
}
.nav .nav-item .nav-link .ion,
.nav .nav-item .nav-link .fas,
.nav .nav-item .nav-link .far,
.nav .nav-item .nav-link .fab,
.nav .nav-item .nav-link .fal {
  margin-right: 3px;
  font-size: 12px;
}
.page-item .page-link {
  color: #6777ef;
  border-radius: 3px;
  margin: 0 3px;
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}
.page-item.active .page-link {
  background-color: #6777ef;
  border-color: #6777ef;
}
.page-item.disabled .page-link {
  border-color: transparent;
  background-color: #f9fafe;
  color: #6777ef;
  opacity: 0.6;
}
.page-link {
  border-color: transparent;
  background-color: #f9fafe;
  font-weight: 600;
}
.page-link:hover {
  background-color: #6777ef;
  color: #fff;
  border-color: transparent;
}
.page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.badges .badge {
  margin: 0 8px 10px 0;
}
.badge {
  vertical-align: middle;
  padding: 7px 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  border-radius: 30px;
  font-size: 12px;
}
.badge.badge-warning {
  color: #fff;
}
.badge.badge-primary {
  background-color: #6777ef;
}
.badge.badge-secondary {
  background-color: #34395e;
}
.badge.badge-success {
  background-color: #54ca68;
}
.badge.badge-info {
  background-color: #3abaf4;
}
.badge.badge-danger {
  background-color: #fc544b;
}
.badge.badge-light {
  background-color: #e3eaef;
  color: #191d21;
}
.badge.badge-white {
  background-color: #fff;
  color: #191d21;
}
.badge.badge-dark {
  background-color: #191d21;
}
h1 .badge {
  font-size: 24px;
  padding: 16px 21px;
}
h2 .badge {
  font-size: 22px;
  padding: 14px 19px;
}
h3 .badge {
  font-size: 18px;
  padding: 11px 16px;
}
h4 .badge {
  font-size: 16px;
  padding: 8px 13px;
}
h5 .badge {
  font-size: 14px;
  padding: 5px 10px;
}
h6 .badge {
  font-size: 11px;
  padding: 3px 8px;
}
.btn .badge {
  margin-left: 5px;
  padding: 4px 7px;
}
.btn .badge.badge-transparent {
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}
.buttons .btn {
  margin: 0 8px 10px 0;
}
.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0;
}
.btn:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0;
}
.btn:active:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0;
}
.btn.btn-icon-split i,
.dropdown-item.has-icon i {
  text-align: center;
  width: 15px;
  font-size: 15px;
  float: left;
  margin-right: 10px;
}
.btn {
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  padding: 0.3rem 0.8rem;
  letter-spacing: 0.5px;
}
.btn.btn-icon-split {
  position: relative;
}
.btn.btn-icon-split i {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 45px;
  border-radius: 3px 0 0 3px;
  line-height: 32px;
}
.btn.btn-icon-split div {
  margin-left: 40px;
}
.btn.btn-icon-noflo-splitat {
  display: table;
  text-align: right;
}
.btn.btn-icon-noflo-splitat i {
  float: none;
  margin: 0;
  display: table-cell;
  vertical-align: middle;
  width: 30%;
}
.btn.btn-icon-noflo-splitat div {
  display: table-cell;
  vertical-align: middle;
  width: 70%;
  text-align: left;
  padding-left: 10px;
}
.btn:not(.btn-social):not(.btn-social-icon):active,
.btn:not(.btn-social):not(.btn-social-icon):focus,
.btn:not(.btn-social):not(.btn-social-icon):hover {
  border-color: transparent !important;
  background-color: white;
}
.btn > i {
  margin-left: 0 !important;
}
.btn.btn-lg {
  padding: 0.55rem 1.5rem;
  font-size: 12px;
}
.btn.btn-lg.btn-icon-split i {
  line-height: 42px;
}
.btn.btn-lg.btn-icon-split div {
  margin-left: 25px;
}
.btn.btn-sm {
  padding: 0.1rem 0.4rem;
  font-size: 12px;
}
.btn.btn-icon .ion,
.btn.btn-icon .fas,
.btn.btn-icon .far,
.btn.btn-icon .fab,
.btn.btn-icon .fal {
  margin-left: 0 !important;
  font-size: 12px;
}
.btn.btn-icon.icon-left .ion,
.btn.btn-icon.icon-left .fas,
.btn.btn-icon.icon-left .far,
.btn.btn-icon.icon-left .fab,
.btn.btn-icon.icon-left .fal {
  margin-right: 3px;
}
.btn.btn-icon.icon-right .ion,
.btn.btn-icon.icon-right .fas,
.btn.btn-icon.icon-right .far,
.btn.btn-icon.icon-right .fab,
.btn.btn-icon.icon-right .fal {
  margin-left: 3px !important;
}
.btn-action {
  color: #fff !important;
  line-height: 25px;
  font-size: 12px;
  min-width: 35px;
  min-height: 35px;
}
.btn-secondary,
.btn-secondary.disabled {
  -webkit-box-shadow: 0 2px 6px #e1e5e8;
  box-shadow: 0 2px 6px #e1e5e8;
  background-color: #cdd3d8;
  border-color: #cdd3d8;
  color: #fff;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.disabled:hover,
.btn-secondary.disabled:focus,
.btn-secondary.disabled:active {
  background-color: #bfc6cd !important;
  color: #fff !important;
}
.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.disabled:hover,
.btn-outline-secondary.disabled:focus,
.btn-outline-secondary.disabled:active {
  background-color: #cdd3d8 !important;
  color: #fff !important;
}
.btn-success,
.btn-success.disabled {
  -webkit-box-shadow: 0 2px 6px #8edc9c;
  box-shadow: 0 2px 6px #8edc9c;
  background-color: #54ca68;
  border-color: #54ca68;
  color: #fff;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active {
  background-color: #41c457 !important;
  color: #fff !important;
}
.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.disabled:hover,
.btn-outline-success.disabled:focus,
.btn-outline-success.disabled:active {
  background-color: #54ca68 !important;
  color: #fff !important;
}
.btn-danger,
.btn-danger.disabled {
  -webkit-box-shadow: 0 2px 6px #fd9b96;
  box-shadow: 0 2px 6px #fd9b96;
  background-color: #fc544b;
  border-color: #fc544b;
  color: #fff;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active {
  background-color: #fb160a !important;
}
.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.disabled:hover,
.btn-outline-danger.disabled:focus,
.btn-outline-danger.disabled:active {
  background-color: #fb160a !important;
  color: #fff !important;
}
.btn-dark,
.btn-dark.disabled {
  -webkit-box-shadow: 0 2px 6px #728394;
  box-shadow: 0 2px 6px #728394;
  background-color: #191d21;
  border-color: #191d21;
  color: #fff;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.disabled:hover,
.btn-dark.disabled:focus,
.btn-dark.disabled:active {
  background-color: black !important;
}
.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.disabled:hover,
.btn-outline-dark.disabled:focus,
.btn-outline-dark.disabled:active {
  background-color: black !important;
  color: #fff !important;
}
.btn-light,
.btn-light.disabled {
  -webkit-box-shadow: 0 2px 6px #e6ecf1;
  box-shadow: 0 2px 6px #e6ecf1;
  background-color: #e3eaef;
  border-color: #e3eaef;
  color: #191d21;
}
.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.disabled:hover,
.btn-light.disabled:focus,
.btn-light.disabled:active {
  background-color: #c3d2dc !important;
}
.btn-outline-light,
.btn-outline-light.disabled {
  border-color: #e3eaef;
  color: #e3eaef;
}
.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.disabled:hover,
.btn-outline-light.disabled:focus,
.btn-outline-light.disabled:active {
  background-color: #e3eaef !important;
  color: #fff !important;
}
.btn-warning,
.btn-warning.disabled {
  -webkit-box-shadow: 0 2px 6px #ffc473;
  box-shadow: 0 2px 6px #ffc473;
  background-color: #ffa426;
  border-color: #ffa426;
  color: #fff;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active {
  background-color: #ff990d !important;
  color: #fff !important;
}
.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.disabled:hover,
.btn-outline-warning.disabled:focus,
.btn-outline-warning.disabled:active {
  background-color: #ffa426 !important;
  color: #fff !important;
}
.btn-info,
.btn-info.disabled {
  -webkit-box-shadow: 0 2px 6px #82d3f8;
  box-shadow: 0 2px 6px #82d3f8;
  background-color: #3abaf4;
  border-color: #3abaf4;
  color: #fff;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active {
  background-color: #0da8ee !important;
}
.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.disabled:hover,
.btn-outline-info.disabled:focus,
.btn-outline-info.disabled:active {
  background-color: #0da8ee !important;
  color: #fff !important;
}
.btn-primary,
.btn-primary.disabled {
  -webkit-box-shadow: 0 2px 6px #acb5f6;
  box-shadow: 0 2px 6px #acb5f6;
  background-color: #6777ef;
  border-color: #6777ef;
}
.btn-primary:focus,
.btn-primary.disabled:focus {
  background-color: #394eea !important;
}
.btn-primary:focus:active,
.btn-primary.disabled:focus:active {
  background-color: #394eea !important;
}
.btn-primary:active,
.btn-primary:hover,
.btn-primary.disabled:active,
.btn-primary.disabled:hover {
  background-color: #394eea !important;
}
.btn-outline-primary,
.btn-outline-primary.disabled {
  border-color: #6777ef;
  color: #6777ef;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.disabled:hover,
.btn-outline-primary.disabled:focus,
.btn-outline-primary.disabled:active {
  background-color: #6777ef !important;
  color: #fff;
}
.btn-outline-white,
.btn-outline-white.disabled {
  border-color: #fff;
  color: #fff;
}
.btn-outline-white:hover,
.btn-outline-white:focus,
.btn-outline-white:active,
.btn-outline-white.disabled:hover,
.btn-outline-white.disabled:focus,
.btn-outline-white.disabled:active {
  background-color: #fff;
  color: #6777ef;
}
.btn-round {
  border-radius: 30px;
  padding-left: 34px;
  padding-right: 34px;
}
.btn-social-icon,
.btn-social {
  border: 0;
  border-radius: 3px;
}
.btn-social-icon {
  color: #fff !important;
  padding-left: 18px;
  padding-right: 18px;
}
.btn-social-icon > :first-child {
  font-size: 16px;
}
.btn-social {
  padding: 12px 12px 12px 50px;
  color: #fff !important;
  font-weight: 500;
}
.btn-social > :first-child {
  width: 55px;
  line-height: 50px;
  border-right: 0;
}
.btn-reddit {
  color: #000 !important;
}
.btn-group .btn.active {
  background-color: #6777ef;
  color: #fff;
}
.btn-progress {
  position: relative;
  background-image: url("../img/spinner-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  color: transparent !important;
  pointer-events: none;
}
.media .media-right {
  float: right;
  color: #6777ef;
  font-weight: 600;
  font-size: 16px;
}
.media .media-icon {
  font-size: 20px;
  margin-right: 15px;
  line-height: 1;
}
.media .media-title {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 15px;
  color: #34395e;
}
.media .media-title a {
  font-weight: inherit;
  color: #000;
}
.media .media-description {
  line-height: 24px;
  color: #34395e;
}
.media .media-links {
  margin-top: 10px;
}
.media .media-links a {
  font-size: 12px;
  color: #999;
}
.media .media-progressbar {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.media .media-progressbar .progress-text {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #34395e;
}
.media .media-cta {
  margin-left: 40px;
}
.media .media-cta .btn {
  padding: 5px 15px;
  border-radius: 30px;
  font-size: 12px;
}
.media .media-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.media .media-items .media-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  padding: 0 15px;
}
.media .media-items .media-item .media-label {
  font-weight: 600;
  font-size: 12px;
  color: #34395e;
  letter-spacing: 0.5px;
}
.media .media-items .media-item .media-value {
  font-weight: 700;
  font-size: 18px;
}
.breadcrumb {
  background-color: #f9f9f9;
}
.breadcrumb .breadcrumb-item {
  line-height: 1;
}
.breadcrumb .breadcrumb-item i {
  margin-right: 5px;
}
.accordion {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}
.accordion .accordion-header,
.accordion .accordion-body {
  padding: 10px 15px;
}
.accordion .accordion-header {
  background-color: #f9f9f9;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.accordion .accordion-header h4 {
  line-height: 1;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}
.accordion .accordion-header:hover {
  background-color: #f2f2f2;
}
.accordion .accordion-header[aria-expanded="true"] {
  -webkit-box-shadow: 0 2px 6px #acb5f6;
  box-shadow: 0 2px 6px #acb5f6;
  background-color: #6777ef;
  color: #fff;
}
.accordion .accordion-body {
  line-height: 24px;
}
.popover {
  -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border-color: transparent;
}
.popover .manual-arrow {
  position: absolute;
  bottom: -15px;
  font-size: 26px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #fff;
}
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::before {
  border-left-color: #f2f2f2;
}
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::before {
  border-bottom-color: #f2f2f2;
}
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::before {
  border-top-color: #f2f2f2;
}
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::before {
  border-right-color: #f2f2f2;
}
.popover .popover-header {
  background-color: transparent;
  border: 0;
  padding-bottom: 0;
  padding-top: 10px;
}
.popover .popover-body {
  padding: 15px;
  line-height: 24px;
}
.sm-gutters {
  margin-left: -5px;
  margin-right: -5px;
}
.sm-gutters > .col,
.sm-gutters > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}
.navbar {
  height: 70px;
  left: 250px;
  right: 0;
  position: absolute;
  z-index: 890;
  background-color: transparent;
}
.navbar.active {
  background-color: #6777ef;
  -webkit-box-shadow: rgba(103, 119, 239, 0.2) rgba(0, 0, 0, 0.1);
  box-shadow: rgba(103, 119, 239, 0.2) rgba(0, 0, 0, 0.1);
}
.navbar-bg {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: #6777ef;
  z-index: -1;
}
.navbar {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar .navbar-brand {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
}
.navbar .form-inline .form-control {
  background-color: #fff;
  border-color: transparent;
  padding-left: 20px;
  padding-right: 0;
  margin-right: -6px;
  min-height: 46px;
  font-weight: 500;
  border-radius: 3px 0 0 3px;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.navbar .form-inline .form-control:focus,
.navbar .form-inline .form-control:focus + .btn {
  position: relative;
  z-index: 9001;
}
.navbar .form-inline .form-control:focus + .btn + .search-backdrop {
  opacity: 0.6;
  visibility: visible;
}
.navbar
  .form-inline
  .form-control:focus
  + .btn
  + .search-backdrop
  + .search-result {
  opacity: 1;
  visibility: visible;
  top: 80px;
}
.navbar .form-inline .btn {
  border-radius: 0 3px 3px 0;
  background-color: #fff;
  padding: 9px 15px 9px 15px;
  border-color: transparent;
}
.navbar .form-inline .search-element .form-control {
  border-radius: 30px 0 0 30px;
}
.navbar .form-inline .search-element .btn {
  border-radius: 0 30px 30px 0;
}
.navbar .form-inline .search-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.navbar .form-inline .search-result {
  position: absolute;
  z-index: 9002;
  top: 100px;
  background-color: #fff;
  border-radius: 3px;
  width: 450px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.navbar .form-inline .search-result:before {
  position: absolute;
  top: -26px;
  left: 34px;
  content: "\f0d8";
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  color: #fff;
  font-size: 30px;
}
.navbar .form-inline .search-result .search-header {
  padding: 13px 18px 2px 18px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 600;
  font-size: 10px;
  color: #bcc1c6;
}
.navbar .form-inline .search-result .search-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.navbar .form-inline .search-result .search-item a {
  display: block;
  padding: 13px 18px;
  text-decoration: none;
  color: #34395e;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar .form-inline .search-result .search-item a:hover {
  background-color: #fbfbff;
}
.navbar .form-inline .search-result .search-item a:not(.search-close) {
  width: 100%;
}
.navbar .form-inline .search-result .search-item a i {
  margin-left: 0 !important;
}
.navbar .form-inline .search-result .search-item .search-icon {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
}
.navbar .active .nav-link {
  color: #fff;
  font-weight: 700;
}
.navbar .navbar-text {
  color: #fff;
}
.navbar .nav-link {
  color: #f2f2f2;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 100%;
}
.navbar .nav-link.nav-link-lg div {
  margin-top: 3px;
}
.navbar .nav-link.nav-link-lg i {
  margin-left: 0 !important;
  font-size: 18px;
  line-height: 32px;
}
.navbar .nav-link.nav-link-user {
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 600;
  padding-right: 12px !important;
}
.navbar .nav-link.nav-link-user img {
  width: 30px;
}
.navbar .nav-link.nav-link-user:after {
  content: none;
}
.navbar .nav-link.nav-link-img {
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 50%;
  overflow: hidden;
}
.navbar .nav-link.nav-link-img .flag-icon {
  -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  line-height: 18px;
  height: 22px;
  width: 22px;
  background-size: cover;
}
.remove-caret:after {
  display: none;
}
.navbar .nav-link:hover {
  color: #fff;
}
.navbar .nav-link.disabled {
  color: #fff;
  opacity: 0.6;
}
.nav-collapse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 575.98px) {
  body.search-show .navbar .form-inline .search-element {
    display: block;
  }
  .navbar .form-inline .search-element {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    z-index: 892;
    display: none;
  }
  .navbar .form-inline .search-element .form-control {
    float: left;
    border-radius: 3px 0 0 3px;
    width: calc(100% - 43px) !important;
  }
  .navbar .form-inline .search-element .btn {
    margin-top: 1px;
    border-radius: 0 3px 3px 0;
  }
  .navbar .form-inline .search-result {
    width: 100%;
  }
  .navbar .form-inline .search-backdrop {
    display: none;
  }
  .navbar .nav-link.nav-link-lg div {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .navbar .form-inline .search-element {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .collapse {
    position: relative;
  }
  .collapse .navbar-nav {
    position: absolute;
  }
}
@media (max-width: 1024px) {
  .nav-collapse {
    position: relative;
  }
  .nav-collapse .navbar-nav {
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 40px;
    left: 0;
    width: 200px;
    display: none;
  }
  .nav-collapse .navbar-nav.show {
    display: block;
  }
  .nav-collapse .navbar-nav .nav-item:first-child {
    border-radius: 3px 3px 0 0;
  }
  .nav-collapse .navbar-nav .nav-item:last-child {
    border-radius: 0 0 3px 3px;
  }
  .nav-collapse .navbar-nav .nav-item .nav-link {
    background-color: #fff;
    color: #6c757d;
  }
  .nav-collapse .navbar-nav .nav-item .nav-link:hover {
    background-color: #fcfcfd;
    color: #6777ef;
  }
  .nav-collapse .navbar-nav .nav-item:focus > a,
  .nav-collapse .navbar-nav .nav-item.active > a {
    background-color: #6777ef;
    color: #fff;
  }
  .navbar {
    left: 5px;
    right: 0;
  }
  .navbar .dropdown-menu {
    position: absolute;
  }
  .navbar .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
}
.app-dropdown {
  width: 280px !important;
}
.app-icon-dropdown {
  padding-left: 15px;
}
.app-icon-dropdown li {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.app-icon-dropdown li:hover {
  background: rgba(181, 174, 174, 0.22);
}
.app-icon-dropdown li a {
  display: inline-block;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  color: #878787;
  text-transform: capitalize;
  width: 80px;
}
.app-icon-dropdown li a:hover {
  text-decoration: none;
}
.app-icon-dropdown .email-icon .material-icons {
  color: #71aa68;
  width: 100%;
}
.app-icon-dropdown .blog-icon .material-icons {
  color: #0080c0;
  width: 100%;
}
.app-icon-dropdown .cal-icon .material-icons {
  color: #ff8000;
  width: 100%;
}
.app-icon-dropdown .chat-icon .material-icons {
  color: #ff0080;
  width: 100%;
}
.app-icon-dropdown .gallery-icon .material-icons {
  color: #8000ff;
  width: 100%;
}
.app-icon-dropdown .profile-icon .material-icons {
  color: #008040;
  width: 100%;
}
.dropdown-item.has-icon i {
  margin-top: -1px;
  font-size: 13px;
}
.dropdown-menu {
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border: 0;
  width: 200px;
}
.dropdown-menu.show {
  display: block !important;
}
.dropdown-menu a {
  font-size: 13px;
}
.dropdown-menu .dropdown-title {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1.5px;
  font-weight: 700;
  color: #191d21 !important;
  padding: 10px 20px;
  line-height: 20px;
  color: #98a6ad;
}
.dropdown-menu.dropdown-menu-sm a {
  font-size: 14px;
  letter-spacing: normal;
  padding: 10px 20px;
  color: #6c757d;
}
a.dropdown-item {
  padding: 10px 20px;
  font-weight: 500;
  line-height: 1.2;
}
a.dropdown-item:focus,
a.dropdown-item:active,
a.dropdown-item.active {
  background-color: #6777ef;
  color: #fff !important;
}
.dropdown-divider {
  border-top-color: #f9f9f9;
}
.dropdown-list {
  width: 350px;
  padding: 0;
}
.dropdown-list .dropdown-item {
  display: inline-block;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 13px;
  border-bottom: 1px solid #f9f9f9;
}
.dropdown-list .dropdown-item.dropdown-item-header:hover {
  background-color: transparent;
}
.dropdown-list .dropdown-item .time {
  margin-top: 10px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.5px;
}
.dropdown-list .dropdown-item .dropdown-item-avatar {
  float: left;
  width: 50px;
  text-align: right;
  position: relative;
}
.dropdown-list .dropdown-item .dropdown-item-avatar img {
  width: 100%;
}
.dropdown-list .dropdown-item .dropdown-item-avatar .is-online {
  position: absolute;
  bottom: 0;
  right: 0;
}
.dropdown-list .dropdown-item .dropdown-item-desc {
  line-height: 24px;
  white-space: normal;
  color: #34395e;
  margin-left: 60px;
}
.dropdown-list .dropdown-item .dropdown-item-desc b {
  font-weight: 600;
  color: #666;
}
.dropdown-list .dropdown-item .dropdown-item-desc p {
  margin-bottom: 0;
}
.dropdown-list .dropdown-item .dropdown-msg-item-desc {
  margin-left: 13px;
  display: inline-grid;
}
.dropdown-list .dropdown-item:focus {
  background-color: #6777ef;
}
.dropdown-list .dropdown-item:focus .dropdown-item-desc {
  color: #fff !important;
}
.dropdown-list .dropdown-item:focus .dropdown-item-desc b {
  color: #fff !important;
}
.dropdown-list .dropdown-item.dropdown-item-unread:active .dropdown-item-desc {
  color: #6c757d;
}
.dropdown-list
  .dropdown-item.dropdown-item-unread:active
  .dropdown-item-desc
  b {
  color: #6c757d;
}
.dropdown-list .dropdown-item:active .dropdown-item-desc {
  color: #fff;
}
.dropdown-list .dropdown-item:active .dropdown-item-desc b {
  color: #fff;
}
.dropdown-list .dropdown-item.dropdown-item-unread {
  background-color: #fbfbfb;
  border-bottom-color: #f2f2f2;
}
.dropdown-list .dropdown-item.dropdown-item-unread:focus .dropdown-item-desc {
  color: #6c757d !important;
}
.dropdown-list .dropdown-item.dropdown-item-unread:focus .dropdown-item-desc b {
  color: #6c757d !important;
}
.dropdown-list .dropdown-footer,
.dropdown-list .dropdown-header {
  letter-spacing: 0.5px;
  font-weight: 600;
  padding: 15px;
}
.dropdown-list .dropdown-footer a,
.dropdown-list .dropdown-header a {
  font-weight: 600;
}
.dropdown-list .dropdown-list-content {
  height: 350px;
  overflow: hidden;
}
.dropdown-list .dropdown-list-content:not(.is-end):after {
  content: " ";
  position: absolute;
  bottom: 46px;
  left: 0;
  width: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    color-stop(rgba(255, 255, 255, 0.4)),
    to(rgba(255, 255, 255, 0.8))
  );
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.8)
  );
  height: 60px;
}
.dropdown-list .dropdown-list-icons .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dropdown-list .dropdown-list-icons .dropdown-item .dropdown-item-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 42px;
  text-align: center;
}
.dropdown-list .dropdown-list-icons .dropdown-item .dropdown-item-icon i {
  margin: 0;
}
.dropdown-list .dropdown-list-icons .dropdown-item .dropdown-item-desc {
  margin-left: 15px;
  line-height: 20px;
}
.dropdown-list .dropdown-list-icons .dropdown-item .dropdown-item-desc .time {
  margin: 0;
  font-size: 10px;
  color: #aaa;
  float: left;
  width: 100%;
  line-height: 20px;
}
.dropdown-list .dropdown-list-message .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dropdown-list .dropdown-list-message .dropdown-item .dropdown-item-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 42px;
  text-align: center;
}
.dropdown-list .dropdown-list-message .dropdown-item .dropdown-item-icon i {
  margin: 0;
}
.dropdown-list .dropdown-list-message .dropdown-item .dropdown-item-desc {
  margin-left: 15px;
  line-height: 20px;
  width: 100%;
}
.dropdown-list .dropdown-list-message .dropdown-item .dropdown-item-desc .time {
  margin: 0;
  font-size: 10px;
  color: #aaa;
  float: left;
  width: 100%;
  line-height: 20px;
}
.dropdown-list
  .dropdown-list-message
  .dropdown-item
  .dropdown-item-desc
  .messege-text {
  text-transform: none;
  font-size: 12px;
  color: #6d6c6c;
}
.dropdown-list
  .dropdown-list-message
  .dropdown-item
  .dropdown-item-desc
  .message-user {
  font-size: 14px;
  font-weight: 600;
  color: #39393c;
}
.dropdown-flag .dropdown-item {
  font-weight: 600;
}
.dropdown-flag .dropdown-item .flag-icon {
  width: 20px;
  height: 13px;
  margin-right: 7px;
  margin-top: -6px;
}
.dropdown-flag .dropdown-item.active {
  background-color: #6777ef;
  color: #fff;
}
@media (max-width: 575.98px) {
  .dropdown-list-toggle {
    position: static;
  }
  .dropdown-list-toggle .dropdown-list {
    left: 10px !important;
    width: calc(100% - 20px);
  }
}
.tab-content.no-padding > .tab-pane {
  padding: 0;
}
.tab-content > .tab-pane {
  line-height: 10px;
}
.progress {
  -webkit-box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.15);
}
.progress.progress-xs {
  height: 5px;
}
.progress.progress-s {
  height: 7px;
}
.progress-bar {
  background-color: #6777ef;
}
.jumbotron {
  background-color: #e3eaef;
}
.carousel .carousel-caption p {
  font-size: 13px;
  line-height: 24px;
}
.ionicons {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ionicons li {
  width: calc(100% / 8);
  font-size: 40px;
  padding: 40px 20px;
  list-style: none;
  text-align: center;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}
.ionicons li:hover {
  opacity: 0.8;
}
.ionicons li .icon-name {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  font-family: "Segoe UI";
  font-size: 12px;
  margin-top: 10px;
  line-height: 22px;
  background-color: #f9f9f9;
  border-radius: 3px;
  padding: 10px;
  display: none;
}
.icon-preview {
  width: 30px;
}
.icon-preview i {
  font-size: 20px;
}
.icon-container .preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
  border-radius: 5px;
  margin: 5px;
  border: 1px solid #c1bbbb;
}
.icon-container .preview .icon-name {
  padding-left: 10px;
}
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/nunito-v9-latin-regular.eot");
  src:
    local("Nunito Regular"),
    local("Nunito-Regular"),
    url("../fonts/nunito-v9-latin-regulard41d.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/nunito-v9-latin-regular.woff2") format("woff2"),
    url("../fonts/nunito-v9-latin-regular.woff") format("woff"),
    url("../fonts/nunito-v9-latin-regular.ttf") format("truetype"),
    url("../fonts/nunito-v9-latin-regular.svg#Nunito") format("svg");
}
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/nunito-v9-latin-600.eot");
  src:
    local("Nunito SemiBold"),
    local("Nunito-SemiBold"),
    url("../fonts/nunito-v9-latin-600d41d.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/nunito-v9-latin-600.woff2") format("woff2"),
    url("../fonts/nunito-v9-latin-600.woff") format("woff"),
    url("../fonts/nunito-v9-latin-600.ttf") format("truetype"),
    url("../fonts/nunito-v9-latin-600.svg#Nunito") format("svg");
}
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/nunito-v9-latin-700.eot");
  src:
    local("Nunito Bold"),
    local("Nunito-Bold"),
    url("../fonts/nunito-v9-latin-700d41d.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/nunito-v9-latin-700.woff2") format("woff2"),
    url("../fonts/nunito-v9-latin-700.woff") format("woff"),
    url("../fonts/nunito-v9-latin-700.ttf") format("truetype"),
    url("../fonts/nunito-v9-latin-700.svg#Nunito") format("svg");
}
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/nunito-v9-latin-800.eot");
  src:
    local("Nunito ExtraBold"),
    local("Nunito-ExtraBold"),
    url("../fonts/nunito-v9-latin-800d41d.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/nunito-v9-latin-800.woff2") format("woff2"),
    url("../fonts/nunito-v9-latin-800.woff") format("woff"),
    url("../fonts/nunito-v9-latin-800.ttf") format("truetype"),
    url("../fonts/nunito-v9-latin-800.svg#Nunito") format("svg");
}
:root {
  --primary: color(primary);
  --secondary: color(fontdark);
  --success: color(success);
  --info: color(info);
  --warning: color(warning);
  --danger: color(danger);
  --light: color(light);
  --dark: color(dark);
}
body {
  background-color: #f6f6f6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Nunito", "Segoe UI", arial;
  color: #6c757d;
}
a.bb {
  text-decoration: none;
  border-bottom: 1px solid #6777ef;
  padding-bottom: 1px;
}
.form-divider {
  display: inline-block;
  width: 100%;
  margin: 10px 0;
  font-size: 16px;
  font-weight: 600;
}
.ui-sortable-handle,
.sort-handler {
  cursor: move;
}
.text-job {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  color: #34395e;
}
.text-time {
  font-size: 12px;
  color: #666;
  font-weight: 500;
  margin-bottom: 10px;
}
.bullet,
.slash {
  display: inline;
  margin: 0 4px;
}
.bullet:after {
  content: "\2022";
}
.slash:after {
  content: "/";
}
.login-brand {
  margin: 20px 0;
  margin-bottom: 40px;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #666;
  text-align: center;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.budget-price {
  display: inline-block;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 3px;
}
.budget-price .budget-price-square {
  width: 15px;
  height: 3px;
  background-color: #f9f9f9;
}
.budget-price .budget-price-label {
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
}
.gradient-bottom {
  position: relative;
}
.gradient-bottom:after {
  content: " ";
  position: absolute;
  bottom: 41px;
  left: 0;
  width: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    color-stop(rgba(255, 255, 255, 0.4)),
    to(rgba(255, 255, 255, 0.8))
  );
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.8)
  );
  height: 60px;
}
.text-small {
  font-size: 12px;
  line-height: 20px;
}
.text-title {
  font-size: 14px;
  color: #34395e;
  font-weight: 600;
}
.img-shadow {
  -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}
.colors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.colors .color {
  border-radius: 3px;
  width: calc((100% / 4) - 10px);
  padding: 10px;
  height: 60px;
  line-height: 40px;
  text-align: center;
  margin: 5px;
}
blockquote {
  padding: 20px;
  padding-left: 40px;
  font-style: oblique;
  background-color: #f9f9f9;
  border-radius: 3px;
  position: relative;
  font-family: "Time new Romans";
  font-size: 16px;
  letter-spacing: 0.3px;
}
blockquote:before {
  content: '"';
  font-size: 30px;
  position: absolute;
  top: 10px;
  left: 20px;
  opacity: 0.2;
}
blockquote .blockquote-footer {
  margin-top: 10px;
}
.bg-whitesmoke {
  background-color: #f7f9f9 !important;
}
.ion {
  font-size: 15px;
}
.fas,
.far,
.fab,
.fal {
  font-size: 13px;
}
#visitorMap {
  height: 210px;
}
.sidebar-gone-show {
  display: none !important;
}
pre {
  border-radius: 3px;
}
.circle-step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.circle-step .circle-content {
  margin-top: 3px;
  margin-left: 13px;
}
.circle-step .circle {
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 30px;
  font-size: 11px;
  text-align: center;
}
.circle-step .circle.circle-primary {
  border-color: #6777ef;
  color: #6777ef;
}
.pe-none {
  pointer-events: none;
}
.contact-map {
  width: 100%;
  height: 100%;
  min-height: 400px;
}
#visitorMap2,
#visitorMap3 {
  height: 350px;
}
.shadow-primary {
  -webkit-box-shadow: 0 2px 6px #acb5f6;
  box-shadow: 0 2px 6px #acb5f6;
}
.shadow-secondary {
  -webkit-box-shadow: 0 2px 6px #e1e5e8;
  box-shadow: 0 2px 6px #e1e5e8;
}
.shadow-success {
  -webkit-box-shadow: 0 2px 6px #8edc9c;
  box-shadow: 0 2px 6px #8edc9c;
}
.shadow-warning {
  -webkit-box-shadow: 0 2px 6px #ffc473;
  box-shadow: 0 2px 6px #ffc473;
}
.shadow-danger {
  -webkit-box-shadow: 0 2px 6px #fd9b96;
  box-shadow: 0 2px 6px #fd9b96;
}
.shadow-info {
  -webkit-box-shadow: 0 2px 6px #82d3f8;
  box-shadow: 0 2px 6px #82d3f8;
}
.shadow-light {
  -webkit-box-shadow: 0 2px 6px #e6ecf1;
  box-shadow: 0 2px 6px #e6ecf1;
}
.shadow-dark {
  -webkit-box-shadow: 0 2px 6px #728394;
  box-shadow: 0 2px 6px #728394;
}
.is-online {
  width: 10px;
  height: 10px;
  background-color: #54ca68;
  border-radius: 50%;
  display: inline-block;
}
.gutters-xs {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}
.gutters-xs > .col,
.gutters-xs > [class*="col-"] {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}
.beep {
  position: relative;
}
.beep:after {
  content: "";
  position: absolute;
  top: 2px;
  right: 8px;
  width: 7px;
  height: 7px;
  background-color: #ffa426;
  border-radius: 50%;
  -webkit-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 1;
}
.beep.beep-sidebar:after {
  position: static;
  margin-left: 10px;
}
@media (max-width: 575.98px) {
  .fc-overflow {
    width: 100%;
    overflow: auto;
  }
  .fc-overflow #myEvent {
    width: 800px;
  }
  .ionicons li {
    width: calc(100% / 4);
  }
  .icon-wrap {
    width: 100%;
  }
}
.section {
  position: relative;
  z-index: 1;
}
.section > *:first-child {
  margin-top: -7px;
}
.section .section-header {
  padding: 20px;
  /*display: -webkit-box;
    display: -ms-flexbox;
    display: flex;*/
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.section .section-header h1 {
  margin-bottom: 0;
  font-weight: 700;
  display: inline-block;
  font-size: 24px;
  margin-top: 3px;
  color: #34395e;
}
.section .section-header .section-header-back {
  margin-right: 15px;
}
.section .section-header .section-header-back .btn:hover {
  background-color: #6777ef;
  color: #fff;
}
.section .section-header .section-header-button {
  margin-left: 20px;
}
.section .section-header .section-header-breadcrumb {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #e7e8e9;
  padding: 15px;
  border-radius: 30px;
}
.section .section-header .section-header-breadcrumb .breadcrumb-item {
  font-size: 13px;
}
.section .section-header .btn {
  font-size: 12px;
}
.section .section-title {
  font-size: 18px;
  color: #191d21;
  font-weight: 600;
  position: relative;
  margin: 30px 0 25px 0;
}
.section .section-title + .section-lead {
  margin-top: -20px;
}
.main-wrapper-1 .section .section-header {
  margin-left: -30px;
  margin-right: -30px;
  margin-top: -10px;
  border-radius: 0;
  border-top: 1px solid #f9f9f9;
  padding-left: 35px;
  padding-right: 35px;
}
@media (max-width: 575.98px) {
  .section .section-title {
    font-size: 14px;
  }
  .section .section-header {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px !important;
  }
  .section .section-header h1 {
    font-size: 18px;
  }
  .section .section-header .float-right {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
  }
  .section .section-header .section-header-breadcrumb {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin-top: 10px;
  }
}
.page-error {
  height: 100%;
  width: 100%;
  padding-top: 60px;
  text-align: center;
  display: table;
}
.page-error .page-inner {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}
.page-error h1 {
  font-size: 10em;
  font-weight: 700;
}
.page-error .page-description {
  font-size: 18px;
  font-weight: 400;
  color: #34395e;
}
.page-error .page-search {
  margin: 40px auto;
  max-width: 100%;
  width: 350px;
}
.page-error .page-search .form-control {
  border-radius: 30px;
}
.page-error .page-search .btn {
  border-radius: 30px;
  margin-left: 10px;
}
@media (max-width: 575.98px) {
  .page-error .page-search {
    width: 100%;
  }
}
.main-sidebar {
  -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  height: 100%;
  width: 250px;
  background-color: #fff;
  z-index: 880;
  left: 0;
}
.main-sidebar,
.navbar,
.main-content,
.main-footer {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
body.sidebar-gone .main-sidebar {
  left: -250px;
}
.sidebar-mini .hide-sidebar-mini {
  display: none !important;
}
.sidebar-mini .main-sidebar {
  width: 65px;
  overflow: initial !important;
  position: absolute;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sidebar-mini .main-sidebar:after {
  -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  content: " ";
  position: fixed;
  background-color: #fff;
  width: 65px;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  -webkit-animation-name: mini-sidebar;
  animation-name: mini-sidebar;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes mini-sidebar {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mini-sidebar {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.sidebar-mini .main-sidebar .logo-name {
  display: none;
}
.sidebar-mini .main-sidebar .sidebar-user {
  margin: 0;
  height: 70px;
  padding: 15px;
}
.sidebar-mini .main-sidebar .sidebar-user .sidebar-user-picture img {
  width: 35px;
}
.sidebar-mini .main-sidebar .sidebar-user .sidebar-user-details .user-name,
.sidebar-mini .main-sidebar .sidebar-user .sidebar-user-details .user-role {
  display: none;
}
.sidebar-mini .main-sidebar .sidebar-brand-sm {
  display: block;
}
.sidebar-mini .main-sidebar .sidebar-menu > li {
  padding: 10px;
}
.sidebar-mini .main-sidebar .sidebar-menu > li.menu-header {
  padding: 0;
  font-size: 0;
  height: 2px;
}
.sidebar-mini .main-sidebar .sidebar-menu > li > a {
  border-radius: 3px;
  height: 45px;
  padding: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.sidebar-mini .main-sidebar .sidebar-menu > li > a .ion,
.sidebar-mini .main-sidebar .sidebar-menu > li > a .fas,
.sidebar-mini .main-sidebar .sidebar-menu > li > a .far,
.sidebar-mini .main-sidebar .sidebar-menu > li > a .fab,
.sidebar-mini .main-sidebar .sidebar-menu > li > a .fal {
  margin: 0;
  font-size: 20px;
}
.sidebar-mini .main-sidebar .sidebar-menu > li > a span {
  display: none;
}
.sidebar-mini .main-sidebar .sidebar-menu > li > a .badge {
  padding: 5px;
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 10px;
}
.sidebar-mini .main-sidebar .sidebar-menu > li > a.has-dropdown:after {
  content: initial;
}
.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  -webkit-box-shadow: 0 4px 8px #acb5f6;
  box-shadow: 0 4px 8px #acb5f6;
  background-color: #6777ef;
  color: #fff;
}
.sidebar-mini .main-sidebar .sidebar-menu > li ul.dropdown-menu {
  position: absolute;
  background-color: #fff;
  left: 65px;
  top: 10px;
  width: 200px;
  display: none;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.sidebar-mini .main-sidebar .sidebar-menu > li ul.dropdown-menu li > a:focus,
.sidebar-mini .main-sidebar .sidebar-menu > li ul.dropdown-menu li.active > a,
.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a:hover {
  color: #6777ef;
}
.sidebar-mini .main-sidebar .sidebar-menu > li ul.dropdown-menu li a {
  height: 40px;
  background-color: #fff;
}
.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li
  a.has-dropdown:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 14px;
}
.sidebar-mini .main-sidebar .sidebar-menu li:hover > ul.dropdown-menu {
  display: block !important;
}
.sidebar-mini
  .main-sidebar
  .sidebar-menu
  li:hover
  > ul.dropdown-menu
  li:hover
  > a {
  background-color: #fcfcfd;
}
.sidebar-mini
  .main-sidebar
  .sidebar-menu
  li:hover
  > ul.dropdown-menu
  li
  .dropdown-menu {
  left: 200px;
  padding: 0;
}
.sidebar-mini .navbar {
  left: 65px;
}
.sidebar-mini .main-content,
.sidebar-mini .main-footer {
  padding-left: 90px;
}
.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("../img/loading.gif") 50% 50% no-repeat #f9f9f9;
  opacity: 1;
}
.user-img-radious-style {
  border-radius: 6px;
  -webkit-box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
}
.shadow-style {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
body.layout-2 .navbar-bg {
  z-index: 889;
  height: 70px;
}
body.layout-2 .navbar {
  left: 0;
  z-index: 890;
}
body.layout-2 .main-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 50px;
}
body.layout-2 .main-sidebar,
body.layout-2 .main-content,
body.layout-2 .main-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
body.layout-2 .main-sidebar {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: static;
  margin-top: 100px;
  width: 200px;
}
body.layout-2 .main-sidebar .sidebar-menu li.menu-header {
  padding: 0;
}
body.layout-2 .main-sidebar .sidebar-menu li a {
  padding: 0;
}
body.layout-2 .main-sidebar .sidebar-menu li a i {
  width: 10px;
}
body.layout-2 .main-sidebar .sidebar-menu li a.has-dropdown:after {
  right: 0;
}
body.layout-2 .main-sidebar .sidebar-menu li a:hover {
  color: #6777ef;
  background-color: transparent;
}
body.layout-2 .main-sidebar .sidebar-menu li ul.dropdown-menu li a {
  padding-left: 34px;
}
body.layout-2 .main-content {
  padding-top: 107px;
  padding-left: 30px;
  padding-right: 0;
  width: calc(100% - 200px);
}
body.layout-2 .main-footer {
  margin-left: 230px;
  width: calc(100% - 230px);
  padding-left: 0;
  padding-right: 0;
}
body.layout-3 .navbar {
  left: 0;
  right: 0;
}
body.layout-3 .navbar.navbar-secondary {
  -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  top: 70px;
  padding: 0;
  z-index: 889;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item.active
  > .nav-link {
  color: #6777ef;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item.active
  > .nav-link:before {
  left: 35px;
  right: 0;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item:first-child
  .nav-link {
  margin-left: 0;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item:last-child
  .nav-link {
  margin-right: 0;
}
body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item > .nav-link {
  color: #868e96;
  font-size: 13px;
  letter-spacing: 0.3px;
  height: 70px;
  padding: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item
  > .nav-link.has-dropdown {
  margin-right: 35px;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item
  > .nav-link.has-dropdown:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 14px;
  right: -20px;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item
  > .nav-link:before {
  content: " ";
  position: absolute;
  left: initial;
  right: initial;
  bottom: 0;
  height: 2px;
  background-color: #6777ef;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item
  > .nav-link
  span {
  line-height: 74px;
}
body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item > .nav-link i {
  width: 30px;
  font-size: 16px;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item
  > .nav-link:hover {
  color: #191d21 !important;
}
body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item .dropdown-menu {
  padding: 0;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item
  .dropdown-menu
  .nav-item
  .nav-link {
  color: #6c757d;
  font-weight: 600;
  letter-spacing: 0.3px;
  padding: 7px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item
  .dropdown-menu
  .nav-item
  .nav-link.has-dropdown:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 14px;
  right: 15px;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item
  .dropdown-menu
  .nav-item:hover
  > .nav-link {
  background-color: #fcfcfd;
  color: #191d21;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item
  .dropdown-menu
  .nav-item:hover
  > .dropdown-menu {
  display: block !important;
  top: -5px;
  left: 200px;
}
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item
  .dropdown-menu
  .nav-item.active
  > .nav-link,
body.layout-3
  .navbar.navbar-secondary
  .navbar-nav
  > .nav-item
  .dropdown-menu
  .nav-item
  .nav-link:focus {
  background-color: #6777ef;
  color: #fff;
}
body.layout-3 .main-content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 170px;
}
body.layout-3 .main-footer {
  padding-left: 0;
  padding-right: 0;
}
.main-sidebar .sidebar-brand {
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 70px;
  line-height: 70px;
}
.main-sidebar .sidebar-brand.sidebar-brand-sm {
  display: none;
}
.main-sidebar .sidebar-brand a {
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 23px;
  vertical-align: bottom;
  color: #000;
}
.main-sidebar .sidebar-brand a .header-logo {
  height: 40px;
}
.main-sidebar .sidebar-brand a .logo-name {
  vertical-align: middle;
  font-size: 20px;
}
.main-sidebar .sidebar-user {
  display: inline-block;
  width: 100%;
  padding: 10px;
  text-align: center;
}
.main-sidebar .sidebar-user .sidebar-user-picture {
  margin-right: 10px;
}
.main-sidebar .sidebar-user .sidebar-user-picture img {
  width: 60px;
  border-radius: 20%;
}
.main-sidebar .sidebar-user .sidebar-user-details .user-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 7px;
  margin-bottom: 3px;
  font-weight: 600;
  color: #505c66;
}
.main-sidebar .sidebar-user .sidebar-user-details .user-role {
  font-weight: 400;
  color: #868e96;
  font-size: 10px;
  letter-spacing: 0.5px;
}
.main-sidebar .sidebar-menu {
  padding: 0;
  margin: 0;
}
.main-sidebar .sidebar-menu li {
  display: block;
}
.main-sidebar .sidebar-menu li.menu-header {
  padding: 3px 15px;
  color: #868e96;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 600;
}
.main-sidebar .sidebar-menu li.menu-header:not(:first-child) {
  margin-top: 10px;
}
.main-sidebar .sidebar-menu li a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  width: 100%;
  letter-spacing: 0.3px;
  color: #60686f;
  font-weight: 600;
  text-decoration: none;
}
.main-sidebar .sidebar-menu li a .badge {
  float: right;
  padding: 5px 10px;
  margin-top: 2px;
}
.main-sidebar .sidebar-menu li a i {
  width: 28px;
  font-size: 15px;
  margin-right: 10px;
  text-align: center;
}
.main-sidebar .sidebar-menu li a span {
  margin-top: 3px;
  width: 100%;
}
.main-sidebar .sidebar-menu li a:hover {
  background-color: #fcfcfd;
}
.main-sidebar .sidebar-menu li.active a {
  color: #6777ef;
  font-weight: 600;
  background-color: #fcfcfd;
}
.main-sidebar .sidebar-menu li.active ul.dropdown-menu {
  background-color: #fcfcfd;
}
.main-sidebar .sidebar-menu li a.has-dropdown:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 14px;
}
.main-sidebar .sidebar-menu li.active > ul.dropdown-menu {
  display: block;
}
.main-sidebar .sidebar-menu li.active > ul.dropdown-menu li a:hover {
  background-color: #fcfcfd;
}
.main-sidebar .sidebar-menu li ul.dropdown-menu {
  padding: 0;
  margin: 0;
  display: none;
  position: static;
  float: none;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}
.main-sidebar .sidebar-menu li ul.dropdown-menu li a {
  color: #60686f;
  height: 35px;
  padding-left: 55px;
  font-weight: 400;
}
.main-sidebar .sidebar-menu li ul.dropdown-menu li a:hover {
  color: #6777ef;
  background-color: inherit;
}
.main-sidebar .sidebar-menu li ul.dropdown-menu li a:hover:before {
  color: #6777ef;
  font-weight: 600;
  left: 35px;
}
.main-sidebar .sidebar-menu li ul.dropdown-menu li a:before {
  content: "\f30b";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  position: absolute;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  left: 30px;
  color: #868e96;
}
.main-sidebar .sidebar-menu li ul.dropdown-menu li.active > a {
  color: #6777ef;
  font-weight: 600;
}
.main-sidebar .sidebar-menu li ul.dropdown-menu li.active > a:before {
  color: #6777ef;
  font-weight: 600;
}
.main-sidebar .sidebar-menu li ul.dropdown-menu li a i {
  margin-top: 1px;
  text-align: center;
}
.main-sidebar .sidebar-menu li ul.dropdown-menu li ul.dropdown-menu {
  padding-left: 10px;
}
.main-content {
  padding-left: 280px;
  padding-right: 30px;
  padding-top: 80px;
  width: 100%;
  position: relative;
}
.main-footer {
  padding: 20px 30px 20px 280px;
  margin-top: 40px;
  color: #98a6ad;
  border-top: 1px solid #e3eaef;
  display: inline-block;
  background: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
  width: 100%;
}
.main-footer .footer-left {
  float: left;
}
.main-footer .footer-right {
  float: right;
}
.simple-footer {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
body:not(.sidebar-mini) .sidebar-style-1 .sidebar-menu li.active a {
  background-color: #6777ef;
  color: #fff;
}
body:not(.sidebar-mini)
  .sidebar-style-1
  .sidebar-menu
  li.active
  ul.dropdown-menu
  li
  a {
  color: #e8ebfd;
}
body:not(.sidebar-mini)
  .sidebar-style-1
  .sidebar-menu
  li.active
  ul.dropdown-menu
  li
  a:hover {
  background-color: #6777ef;
  color: #fff;
}
body:not(.sidebar-mini)
  .sidebar-style-1
  .sidebar-menu
  li.active
  ul.dropdown-menu
  li.active
  a {
  color: #fff;
}
body:not(.sidebar-mini) .sidebar-style-2 .sidebar-menu > li.active > a {
  padding-left: 20px;
  position: relative;
}
body:not(.sidebar-mini) .sidebar-style-2 .sidebar-menu > li.active > a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 25px;
  width: 4px;
}
body:not(.sidebar-mini)
  .sidebar-style-2
  .sidebar-menu
  li.active
  ul.dropdown-menu
  li
  a {
  background-color: #fff;
}
.theme-setting {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2002;
}
.theme-setting .theme-setting-toggle {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f73f52;
  color: #fff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 60px;
  cursor: pointer;
}
.theme-setting .theme-setting-toggle i {
  font-size: 24px;
}
.theme-setting .theme-setting-options {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  z-index: -1;
  position: absolute;
  left: -220px;
  bottom: 0;
  height: 150px;
  width: 50px;
  background-color: #e8e6e6;
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  visibility: hidden;
  opacity: 0;
}
.theme-setting .theme-setting-options ul {
  padding: 0;
  margin: 0;
  width: 100%;
  display: inline-block;
  margin-left: 20px;
}
.theme-setting .theme-setting-options ul li {
  width: 20px;
  height: 20px;
  background-color: #000;
  margin-right: 10px;
  margin-top: 15px;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.theme-setting .theme-setting-options ul li:hover {
  opacity: 0.8;
}
.theme-setting.active .theme-setting-toggle {
  margin: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: 50px;
  width: 40px;
  height: 40px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.theme-setting.active .theme-setting-options {
  visibility: visible;
  opacity: 1;
  width: 220px;
}
.theme-setting.active .theme-setting-options ul li {
  opacity: 1;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
@media (max-width: 1024px) {
  .sidebar-gone-hide {
    display: none !important;
  }
  .sidebar-gone-show {
    display: block !important;
  }
  .main-sidebar {
    position: fixed !important;
    margin-top: 0 !important;
    z-index: 891;
  }
  body.layout-2 .main-wrapper,
  body.layout-3 .main-wrapper {
    width: 100%;
    padding: 0;
    display: block;
  }
  .main-content {
    padding-left: 30px;
    padding-right: 30px;
    width: 100% !important;
  }
  .main-footer {
    padding-left: 30px;
  }
  body.search-show {
    overflow: hidden;
  }
  body.search-show .navbar {
    z-index: 892;
  }
  body.sidebar-show {
    overflow: hidden;
  }
  body.search-show:before,
  body.sidebar-show:before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    z-index: 891;
    -webkit-animation-name: fadeinbackdrop;
    animation-name: fadeinbackdrop;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  @-webkit-keyframes fadeinbackdrop {
    to {
      opacity: 0.6;
    }
  }
  @keyframes fadeinbackdrop {
    to {
      opacity: 0.6;
    }
  }
}
.pulsate {
  -webkit-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 1;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
.dark-sidebar:not(.sidebar-mini)
  .sidebar-style-2
  .sidebar-menu
  > li.active
  ul.dropdown-menu
  li
  a {
  background-color: #353c48;
}
.dark-sidebar.sidebar-mini .main-sidebar .sidebar-menu {
  background-color: #353c48;
}
.dark-sidebar.sidebar-mini .main-sidebar .sidebar-menu li.active a {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}
.dark-sidebar.sidebar-mini
  .main-sidebar
  .sidebar-menu
  li
  ul.dropdown-menu
  li
  a {
  background-color: #353c48;
}
.dark-sidebar.sidebar-mini
  .main-sidebar
  .sidebar-menu
  li
  ul.dropdown-menu
  li:hover
  a {
  background-color: #353c48;
}
.dark-sidebar.sidebar-mini .main-sidebar .sidebar-user {
  background-color: #353c48;
}
.dark-sidebar.sidebar-mini .main-sidebar .dropdown-title {
  background-color: #353c48;
  color: #fff !important;
}
.dark-sidebar.sidebar-mini .main-sidebar:after {
  background-color: #353c48;
}
.dark-sidebar .navbar.active {
  background-color: #fff;
}
.dark-sidebar .navbar .form-inline .form-control {
  background-color: #f2f2f2;
}
.dark-sidebar .navbar .form-inline .btn {
  background-color: #f2f2f2;
}
.dark-sidebar .main-sidebar {
  background-color: #353c48;
}
.dark-sidebar .main-sidebar .sidebar-brand {
  background-color: #353c48;
}
.dark-sidebar .main-sidebar .sidebar-brand a {
  color: #fff;
}
.dark-sidebar .main-sidebar .sidebar-menu li a {
  color: #e2e2e2;
}
.dark-sidebar .main-sidebar .sidebar-menu li.menu-header {
  color: #ebecf1;
}
.dark-sidebar .main-sidebar .sidebar-menu li.active a {
  background-color: rgba(31, 32, 46, 0.3);
}
.dark-sidebar .main-sidebar .sidebar-menu li ul.menu-dropdown li a {
  color: #868e96;
}
.dark-sidebar .main-sidebar .sidebar-menu li ul.menu-dropdown li.active a {
  color: #fff;
}
.dark-sidebar .main-sidebar .sidebar-menu li a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.dark-sidebar .main-sidebar .sidebar-menu li ul.dropdown-menu a {
  color: #a9b7d0;
}
.dark-sidebar .main-sidebar .sidebar-menu li ul.dropdown-menu li.active > a {
  color: #e2e2e2;
}
.dark-sidebar
  .main-sidebar
  .sidebar-menu
  li
  ul.dropdown-menu
  li.active
  > a:before {
  color: #e2e2e2;
}
.dark-sidebar .main-sidebar .sidebar-menu li ul.dropdown-menu li a:hover {
  color: #fff;
}
.dark-sidebar
  .main-sidebar
  .sidebar-menu
  li
  ul.dropdown-menu
  li
  a:hover:before {
  color: #fff;
}
.dark-sidebar .main-sidebar .sidebar-user .sidebar-user-details .user-name {
  color: #ededed;
}
.dark-sidebar .btn-primary,
.dark-sidebar .btn-primary.disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.light-sidebar:not(.sidebar-mini)
  .sidebar-style-2
  .sidebar-menu
  > li.active
  ul.dropdown-menu
  li
  a {
  background-color: #fff !important;
}
.light-sidebar.sidebar-mini .main-sidebar .sidebar-menu {
  background-color: #fff;
}
.light-sidebar.sidebar-mini .main-sidebar .sidebar-menu li.active a {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.light-sidebar.sidebar-mini
  .main-sidebar
  .sidebar-menu
  li
  ul.dropdown-menu
  li
  a {
  background-color: #fff;
}
.light-sidebar.sidebar-mini
  .main-sidebar
  .sidebar-menu
  li
  ul.dropdown-menu
  li:hover
  a {
  background-color: #fff;
}
.light-sidebar.sidebar-mini .main-sidebar .sidebar-user {
  background-color: #fff;
}
.light-sidebar.sidebar-mini .main-sidebar .dropdown-title {
  background-color: #fff;
}
.light-sidebar.sidebar-mini .main-sidebar:after {
  background-color: #fff;
}
.light-sidebar .navbar.active {
  background-color: #fff;
}
.light-sidebar .navbar .form-inline .form-control {
  background-color: #f2f2f2;
}
.light-sidebar .navbar .form-inline .btn {
  background-color: #f2f2f2;
}
.light-sidebar .main-sidebar .sidebar-menu li.active a {
  color: #454546;
  background-color: rgba(0, 0, 0, 0.14);
}
.light-sidebar .main-sidebar .sidebar-menu li ul.dropdown-menu li.active > a {
  color: #454546;
  font-weight: 600;
}
.light-sidebar
  .main-sidebar
  .sidebar-menu
  li
  ul.dropdown-menu
  li.active
  > a::before {
  color: #454546;
}
.light-sidebar .main-sidebar .sidebar-menu li ul.dropdown-menu li a:hover {
  color: #454546;
}
.light-sidebar
  .main-sidebar
  .sidebar-menu
  li
  ul.dropdown-menu
  li
  a:hover::before {
  color: #454546;
}
.theme-orange .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-orange .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-orange .show > .btn-outline-primary.dropdown-toggle,
.theme-cyan .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-cyan .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-cyan .show > .btn-outline-primary.dropdown-toggle,
.theme-green .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-green .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-green .show > .btn-outline-primary.dropdown-toggle,
.theme-red .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-red .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-red .show > .btn-outline-primary.dropdown-toggle,
.theme-white .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-white .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-white .show > .btn-outline-primary.dropdown-toggle,
.theme-black .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-black .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-black .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #5864bd;
  border-color: #5864bd;
}
.theme-orange.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  background-color: rgba(0, 0, 0, 0.14);
}
.theme-orange.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a:hover {
  color: #5864bd;
}
.theme-orange .bg-primary {
  background-color: #5864bd !important;
}
.theme-orange .text-primary {
  color: #5864bd !important;
}
.theme-orange a {
  color: #5864bd;
}
.theme-orange a:hover {
  color: #5864bd;
}
.theme-orange .btn-primary {
  background-color: #5864bd;
  border-color: transparent !important;
  color: #fff;
}
.theme-orange .btn-primary:focus {
  background-color: #5864bd !important;
}
.theme-orange .btn-primary:focus:active {
  background-color: #5864bd !important;
}
.theme-orange .btn-primary:active {
  background-color: #5864bd !important;
}
.theme-orange .btn-primary:hover {
  background-color: #5864bd !important;
  color: #fff;
}
.theme-orange .btn-primary.disabled {
  background-color: #5864bd;
  border-color: #5864bd;
}
.theme-orange .btn-primary:disabled {
  background-color: #5864bd;
  border-color: #5864bd;
}
.theme-orange .btn-outline-primary {
  color: #5864bd;
  background-color: transparent;
  background-image: none;
  border-color: #5864bd;
}
.theme-orange .btn-outline-primary:hover {
  color: #fff;
  background-color: #5864bd;
  border-color: #5864bd;
}
.theme-orange .btn-outline-primary.disabled {
  color: #5864bd;
  background-color: transparent;
}
.theme-orange .btn-outline-primary:disabled {
  color: #5864bd;
  background-color: transparent;
}
.theme-orange .btn-link {
  font-weight: 400;
  color: #5864bd;
  background-color: transparent;
}
.theme-orange .btn-link:hover {
  color: #5864bd;
}
.theme-orange .dropdown-item.active {
  color: #fff;
  background-color: #5864bd;
}
.theme-orange .dropdown-item:active {
  color: #fff;
  background-color: #5864bd;
}
.theme-orange .nav-pills .nav-link.active {
  color: #fff;
  background-color: #5864bd;
}
.theme-orange .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5864bd;
}
.theme-orange .page-link {
  color: #5864bd;
  background-color: #fff;
  border: 1px solid #ededed;
}
.theme-orange .page-link:focus {
  color: #5864bd;
}
.theme-orange .page-link:hover {
  color: #5864bd;
  background-color: #eaeaea;
}
.theme-orange .page-item .page-link {
  color: #5864bd;
}
.theme-orange .page-item.active .page-link {
  color: #fff;
  background-color: #5864bd;
  border-color: #5864bd;
}
.theme-orange .page-item.disabled .page-link {
  color: #5864bd;
}
.theme-orange .progress-bar {
  color: #fff;
  background-color: #5864bd;
}
.theme-orange .border-primary {
  border-color: #5864bd !important;
}
.theme-orange .navbar-bg {
  background-color: #5864bd;
}
.theme-orange .jqvmap-circle {
  background-color: #5864bd;
  border: 1px solid #000;
}
.theme-orange .dropzone {
  border: 2px dashed #5864bd;
}
.theme-orange .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #5864bd;
}
.theme-orange
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #5864bd;
}
.theme-orange
  .custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: #5864bd;
}
.theme-orange
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #5864bd;
}
.theme-orange .list-group-item.active {
  color: #fff;
  background-color: #5864bd;
  border-color: #5864bd;
}
.theme-orange .navbar.active {
  background-color: #5864bd;
}
.theme-orange .form-control:focus {
  border-color: #5864bd;
}
.theme-orange .alert.alert-primary {
  background-color: #5864bd;
}
.theme-orange .card.card-primary {
  border-top: 2px solid #5864bd;
}
.theme-orange .fc button.fc-state-active {
  background-color: #5864bd;
  color: #fff;
}
.theme-orange .weather ul li {
  border: 2px solid #5864bd;
  color: #5864bd;
}
.theme-orange
  .card-chat
  .chat-content
  .chat-item.chat-right
  .chat-details
  .chat-text {
  background-color: #5864bd;
  color: #fff;
}
.theme-orange .nav-tabs .nav-item .nav-link {
  color: #5864bd;
}
.theme-orange .swal-button.swal-button--confirm {
  background-color: #5864bd;
}
.theme-orange .btn-group .btn.active {
  background-color: #5864bd;
  color: #fff;
}
.theme-orange .media .media-right {
  color: #5864bd;
}
.theme-orange .selectric-items li.selected {
  background-color: #5864bd;
  color: #fff;
}
.theme-orange .selectric-items li.highlighted {
  background-color: #5864bd;
  color: #fff;
}
.theme-orange .accordion .accordion-header[aria-expanded="true"] {
  background-color: #5864bd;
  color: #fff;
}
.theme-orange .bootstrap-tagsinput .tag {
  background-color: #5864bd;
}
.theme-orange
  body:not(.sidebar-mini)
  .sidebar-style-2
  .sidebar-menu
  > li.active
  > a:before {
  background-color: #5864bd;
}
.theme-orange body.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  -webkit-box-shadow: 0 4px 8px #5e6cdd;
  box-shadow: 0 4px 8px #5e6cdd;
  background-color: #5864bd;
  color: #fff;
}
.theme-orange
  body.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a {
  color: #5864bd;
}
.theme-orange .activities .activity:before {
  background-color: #5864bd;
}
.theme-orange .settingSidebar .settingPanelToggle {
  background: #5864bd;
}
.theme-orange .settingSidebar .settingPanelToggle i {
  color: #fff;
}
.theme-orange .sidebar-color .selectgroup-input:checked + .selectgroup-button {
  background-color: #5864bd;
}
.theme-orange .custom-switch-input:checked ~ .custom-switch-indicator {
  background: #5864bd;
}
.theme-orange .selectgroup-input:focus + .selectgroup-button,
.theme-orange .selectgroup-input:checked + .selectgroup-button {
  background-color: #5864bd;
}
.theme-orange .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-orange .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-orange .show > .btn-outline-primary.dropdown-toggle,
.theme-cyan .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-cyan .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-cyan .show > .btn-outline-primary.dropdown-toggle,
.theme-green .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-green .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-green .show > .btn-outline-primary.dropdown-toggle,
.theme-red .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-red .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-red .show > .btn-outline-primary.dropdown-toggle,
.theme-white .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-white .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-white .show > .btn-outline-primary.dropdown-toggle,
.theme-black .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-black .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-black .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #3dc9b3;
  border-color: #3dc9b3;
}
.theme-cyan.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  background-color: rgba(0, 0, 0, 0.14);
}
.theme-cyan.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a:hover {
  color: #3dc9b3;
}
.theme-cyan .bg-primary {
  background-color: #3dc9b3 !important;
}
.theme-cyan .text-primary {
  color: #3dc9b3 !important;
}
.theme-cyan a {
  color: #3dc9b3;
}
.theme-cyan a:hover {
  color: #4bded5;
}
.theme-cyan .btn-primary {
  background-color: #3dc9b3;
  border-color: transparent !important;
  color: #fff;
}
.theme-cyan .btn-primary:focus {
  background-color: #4bded5 !important;
}
.theme-cyan .btn-primary:focus:active {
  background-color: #4bded5 !important;
}
.theme-cyan .btn-primary:active {
  background-color: #4bded5 !important;
}
.theme-cyan .btn-primary:hover {
  background-color: #4bded5 !important;
  color: #fff;
}
.theme-cyan .btn-primary.disabled {
  background-color: #3dc9b3;
  border-color: #3dc9b3;
}
.theme-cyan .btn-primary:disabled {
  background-color: #3dc9b3;
  border-color: #3dc9b3;
}
.theme-cyan .btn-outline-primary {
  color: #3dc9b3;
  background-color: transparent;
  background-image: none;
  border-color: #3dc9b3;
}
.theme-cyan .btn-outline-primary:hover {
  color: #fff;
  background-color: #3dc9b3;
  border-color: #3dc9b3;
}
.theme-cyan .btn-outline-primary.disabled {
  color: #3dc9b3;
  background-color: transparent;
}
.theme-cyan .btn-outline-primary:disabled {
  color: #3dc9b3;
  background-color: transparent;
}
.theme-cyan .btn-link {
  font-weight: 400;
  color: #3dc9b3;
  background-color: transparent;
}
.theme-cyan .btn-link:hover {
  color: #4bded5;
}
.theme-cyan .dropdown-item.active {
  color: #fff;
  background-color: #3dc9b3;
}
.theme-cyan .dropdown-item:active {
  color: #fff;
  background-color: #3dc9b3;
}
.theme-cyan .nav-pills .nav-link.active {
  color: #fff;
  background-color: #3dc9b3;
}
.theme-cyan .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3dc9b3;
}
.theme-cyan .page-link {
  color: #3dc9b3;
  background-color: #fff;
  border: 1px solid #ededed;
}
.theme-cyan .page-link:focus {
  color: #4bded5;
}
.theme-cyan .page-link:hover {
  color: #4bded5;
  background-color: #eaeaea;
}
.theme-cyan .page-item .page-link {
  color: #3dc9b3;
}
.theme-cyan .page-item.active .page-link {
  color: #fff;
  background-color: #3dc9b3;
  border-color: #3dc9b3;
}
.theme-cyan .page-item.disabled .page-link {
  color: #3dc9b3;
}
.theme-cyan .progress-bar {
  color: #fff;
  background-color: #3dc9b3;
}
.theme-cyan .border-primary {
  border-color: #3dc9b3 !important;
}
.theme-cyan .navbar-bg {
  background-color: #3dc9b3;
}
.theme-cyan .jqvmap-circle {
  background-color: #3dc9b3;
  border: 1px solid #000;
}
.theme-cyan .dropzone {
  border: 2px dashed #3dc9b3;
}
.theme-cyan .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #3dc9b3;
}
.theme-cyan
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #3dc9b3;
}
.theme-cyan
  .custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: #3dc9b3;
}
.theme-cyan
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #3dc9b3;
}
.theme-cyan .list-group-item.active {
  color: #fff;
  background-color: #3dc9b3;
  border-color: #3dc9b3;
}
.theme-cyan .navbar.active {
  background-color: #3dc9b3;
}
.theme-cyan .form-control:focus {
  border-color: #3dc9b3;
}
.theme-cyan .alert.alert-primary {
  background-color: #3dc9b3;
}
.theme-cyan .card.card-primary {
  border-top: 2px solid #3dc9b3;
}
.theme-cyan .fc button.fc-state-active {
  background-color: #3dc9b3;
  color: #fff;
}
.theme-cyan .weather ul li {
  border: 2px solid #3dc9b3;
  color: #3dc9b3;
}
.theme-cyan
  .card-chat
  .chat-content
  .chat-item.chat-right
  .chat-details
  .chat-text {
  background-color: #3dc9b3;
  color: #fff;
}
.theme-cyan .nav-tabs .nav-item .nav-link {
  color: #3dc9b3;
}
.theme-cyan .swal-button.swal-button--confirm {
  background-color: #3dc9b3;
}
.theme-cyan .btn-group .btn.active {
  background-color: #3dc9b3;
  color: #fff;
}
.theme-cyan .media .media-right {
  color: #3dc9b3;
}
.theme-cyan .selectric-items li.selected {
  background-color: #3dc9b3;
  color: #fff;
}
.theme-cyan .selectric-items li.highlighted {
  background-color: #3dc9b3;
  color: #fff;
}
.theme-cyan .accordion .accordion-header[aria-expanded="true"] {
  background-color: #3dc9b3;
  color: #fff;
}
.theme-cyan .bootstrap-tagsinput .tag {
  background-color: #3dc9b3;
}
.theme-cyan
  body:not(.sidebar-mini)
  .sidebar-style-2
  .sidebar-menu
  > li.active
  > a:before {
  background-color: #3dc9b3;
}
.theme-cyan body.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  -webkit-box-shadow: 0 4px 8px #8ddcd7;
  box-shadow: 0 4px 8px #8ddcd7;
  background-color: #3dc9b3;
  color: #fff;
}
.theme-cyan
  body.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a {
  color: #3dc9b3;
}
.theme-cyan .activities .activity:before {
  background-color: #3dc9b3;
}
.theme-cyan .settingSidebar .settingPanelToggle {
  background: #3dc9b3;
}
.theme-cyan .settingSidebar .settingPanelToggle i {
  color: #fff;
}
.theme-cyan .sidebar-color .selectgroup-input:checked + .selectgroup-button {
  background-color: #3dc9b3;
}
.theme-cyan .custom-switch-input:checked ~ .custom-switch-indicator {
  background: #3dc9b3;
}
.theme-cyan .selectgroup-input:focus + .selectgroup-button,
.theme-cyan .selectgroup-input:checked + .selectgroup-button {
  background-color: #3dc9b3;
}
.theme-orange .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-orange .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-orange .show > .btn-outline-primary.dropdown-toggle,
.theme-cyan .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-cyan .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-cyan .show > .btn-outline-primary.dropdown-toggle,
.theme-green .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-green .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-green .show > .btn-outline-primary.dropdown-toggle,
.theme-red .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-red .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-red .show > .btn-outline-primary.dropdown-toggle,
.theme-white .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-white .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-white .show > .btn-outline-primary.dropdown-toggle,
.theme-black .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-black .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-black .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #28c76f;
  border-color: #28c76f;
}
.theme-green.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  background-color: rgba(0, 0, 0, 0.14);
}
.theme-green.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a:hover {
  color: #28c76f;
}
.theme-green .bg-primary {
  background-color: #28c76f !important;
}
.theme-green .text-primary {
  color: #28c76f !important;
}
.theme-green a {
  color: #28c76f;
}
.theme-green a:hover {
  color: #85d888;
}
.theme-green .btn-primary {
  background-color: #28c76f;
  border-color: transparent !important;
  color: #fff;
}
.theme-green .btn-primary:focus {
  background-color: #85d888 !important;
}
.theme-green .btn-primary:focus:active {
  background-color: #85d888 !important;
}
.theme-green .btn-primary:active {
  background-color: #85d888 !important;
}
.theme-green .btn-primary:hover {
  background-color: #85d888 !important;
  color: #fff;
}
.theme-green .btn-primary.disabled {
  background-color: #28c76f;
  border-color: #28c76f;
}
.theme-green .btn-primary:disabled {
  background-color: #28c76f;
  border-color: #28c76f;
}
.theme-green .btn-outline-primary {
  color: #28c76f;
  background-color: transparent;
  background-image: none;
  border-color: #28c76f;
}
.theme-green .btn-outline-primary:hover {
  color: #fff;
  background-color: #28c76f;
  border-color: #28c76f;
}
.theme-green .btn-outline-primary.disabled {
  color: #28c76f;
  background-color: transparent;
}
.theme-green .btn-outline-primary:disabled {
  color: #28c76f;
  background-color: transparent;
}
.theme-green .btn-link {
  font-weight: 400;
  color: #28c76f;
  background-color: transparent;
}
.theme-green .btn-link:hover {
  color: #85d888;
}
.theme-green .dropdown-item.active {
  color: #fff;
  background-color: #28c76f;
}
.theme-green .dropdown-item:active {
  color: #fff;
  background-color: #28c76f;
}
.theme-green .nav-pills .nav-link.active {
  color: #fff;
  background-color: #28c76f;
}
.theme-green .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #28c76f;
}
.theme-green .page-link {
  color: #28c76f;
  background-color: #fff;
  border: 1px solid #ededed;
}
.theme-green .page-link:focus {
  color: #85d888;
}
.theme-green .page-link:hover {
  color: #85d888;
  background-color: #eaeaea;
}
.theme-green .page-item .page-link {
  color: #28c76f;
}
.theme-green .page-item.active .page-link {
  color: #fff;
  background-color: #28c76f;
  border-color: #28c76f;
}
.theme-green .page-item.disabled .page-link {
  color: #28c76f;
}
.theme-green .progress-bar {
  color: #fff;
  background-color: #28c76f;
}
.theme-green .border-primary {
  border-color: #28c76f !important;
}
.theme-green .navbar-bg {
  background-color: #28c76f;
}
.theme-green .jqvmap-circle {
  background-color: #28c76f;
  border: 1px solid #000;
}
.theme-green .dropzone {
  border: 2px dashed #28c76f;
}
.theme-green .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #28c76f;
}
.theme-green
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #28c76f;
}
.theme-green
  .custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: #28c76f;
}
.theme-green
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #28c76f;
}
.theme-green .list-group-item.active {
  color: #fff;
  background-color: #28c76f;
  border-color: #28c76f;
}
.theme-green .navbar.active {
  background-color: #28c76f;
}
.theme-green .form-control:focus {
  border-color: #28c76f;
}
.theme-green .alert.alert-primary {
  background-color: #28c76f;
}
.theme-green .card.card-primary {
  border-top: 2px solid #28c76f;
}
.theme-green .fc button.fc-state-active {
  background-color: #28c76f;
  color: #fff;
}
.theme-green .weather ul li {
  border: 2px solid #28c76f;
  color: #28c76f;
}
.theme-green
  .card-chat
  .chat-content
  .chat-item.chat-right
  .chat-details
  .chat-text {
  background-color: #28c76f;
  color: #fff;
}
.theme-green .nav-tabs .nav-item .nav-link {
  color: #28c76f;
}
.theme-green .swal-button.swal-button--confirm {
  background-color: #28c76f;
}
.theme-green .btn-group .btn.active {
  background-color: #28c76f;
  color: #fff;
}
.theme-green .media .media-right {
  color: #28c76f;
}
.theme-green .selectric-items li.selected {
  background-color: #28c76f;
  color: #fff;
}
.theme-green .selectric-items li.highlighted {
  background-color: #28c76f;
  color: #fff;
}
.theme-green .accordion .accordion-header[aria-expanded="true"] {
  background-color: #28c76f;
  color: #fff;
}
.theme-green .bootstrap-tagsinput .tag {
  background-color: #28c76f;
}
.theme-green
  body:not(.sidebar-mini)
  .sidebar-style-2
  .sidebar-menu
  > li.active
  > a:before {
  background-color: #28c76f;
}
.theme-green body.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  -webkit-box-shadow: 0 4px 8px #64c367;
  box-shadow: 0 4px 8px #64c367;
  background-color: #28c76f;
  color: #fff;
}
.theme-green
  body.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a {
  color: #28c76f;
}
.theme-green .activities .activity:before {
  background-color: #28c76f;
}
.theme-green .settingSidebar .settingPanelToggle {
  background: #28c76f;
}
.theme-green .settingSidebar .settingPanelToggle i {
  color: #fff;
}
.theme-green .sidebar-color .selectgroup-input:checked + .selectgroup-button {
  background-color: #28c76f;
}
.theme-green .custom-switch-input:checked ~ .custom-switch-indicator {
  background: #28c76f;
}
.theme-green .selectgroup-input:focus + .selectgroup-button,
.theme-green .selectgroup-input:checked + .selectgroup-button {
  background-color: #28c76f;
}
.theme-orange .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-orange .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-orange .show > .btn-outline-primary.dropdown-toggle,
.theme-cyan .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-cyan .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-cyan .show > .btn-outline-primary.dropdown-toggle,
.theme-green .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-green .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-green .show > .btn-outline-primary.dropdown-toggle,
.theme-red .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-red .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-red .show > .btn-outline-primary.dropdown-toggle,
.theme-white .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-white .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-white .show > .btn-outline-primary.dropdown-toggle,
.theme-black .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-black .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-black .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #ea5455;
  border-color: #ea5455;
}
.theme-red.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  background-color: rgba(0, 0, 0, 0.14);
}
.theme-red.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a:hover {
  color: #ea5455;
}
.theme-red .bg-primary {
  background-color: #ea5455 !important;
}
.theme-red .text-primary {
  color: #ea5455 !important;
}
.theme-red a {
  color: #ea5455;
}
.theme-red a:hover {
  color: #d8595a;
}
.theme-red .btn-primary {
  background-color: #ea5455;
  border-color: transparent !important;
  color: #fff;
}
.theme-red .btn-primary:focus {
  background-color: #d8595a !important;
}
.theme-red .btn-primary:focus:active {
  background-color: #d8595a !important;
}
.theme-red .btn-primary:active {
  background-color: #d8595a !important;
}
.theme-red .btn-primary:hover {
  background-color: #d8595a !important;
  color: #fff;
}
.theme-red .btn-primary.disabled {
  background-color: #ea5455;
  border-color: #ea5455;
}
.theme-red .btn-primary:disabled {
  background-color: #ea5455;
  border-color: #ea5455;
}
.theme-red .btn-outline-primary {
  color: #ea5455;
  background-color: transparent;
  background-image: none;
  border-color: #ea5455;
}
.theme-red .btn-outline-primary:hover {
  color: #fff;
  background-color: #ea5455;
  border-color: #ea5455;
}
.theme-red .btn-outline-primary.disabled {
  color: #ea5455;
  background-color: transparent;
}
.theme-red .btn-outline-primary:disabled {
  color: #ea5455;
  background-color: transparent;
}
.theme-red .btn-link {
  font-weight: 400;
  color: #ea5455;
  background-color: transparent;
}
.theme-red .btn-link:hover {
  color: #d8595a;
}
.theme-red .dropdown-item.active {
  color: #fff;
  background-color: #ea5455;
}
.theme-red .dropdown-item:active {
  color: #fff;
  background-color: #ea5455;
}
.theme-red .nav-pills .nav-link.active {
  color: #fff;
  background-color: #ea5455;
}
.theme-red .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ea5455;
}
.theme-red .page-link {
  color: #ea5455;
  background-color: #fff;
  border: 1px solid #ededed;
}
.theme-red .page-link:focus {
  color: #d8595a;
}
.theme-red .page-link:hover {
  color: #d8595a;
  background-color: #eaeaea;
}
.theme-red .page-item .page-link {
  color: #ea5455;
}
.theme-red .page-item.active .page-link {
  color: #fff;
  background-color: #ea5455;
  border-color: #ea5455;
}
.theme-red .page-item.disabled .page-link {
  color: #ea5455;
}
.theme-red .progress-bar {
  color: #fff;
  background-color: #ea5455;
}
.theme-red .border-primary {
  border-color: #ea5455 !important;
}
.theme-red .navbar-bg {
  background-color: #ea5455;
}
.theme-red .jqvmap-circle {
  background-color: #ea5455;
  border: 1px solid #000;
}
.theme-red .dropzone {
  border: 2px dashed #ea5455;
}
.theme-red .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #ea5455;
}
.theme-red
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #ea5455;
}
.theme-red
  .custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: #ea5455;
}
.theme-red
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #ea5455;
}
.theme-red .list-group-item.active {
  color: #fff;
  background-color: #ea5455;
  border-color: #ea5455;
}
.theme-red .navbar.active {
  background-color: #ea5455;
}
.theme-red .form-control:focus {
  border-color: #ea5455;
}
.theme-red .alert.alert-primary {
  background-color: #ea5455;
}
.theme-red .card.card-primary {
  border-top: 2px solid #ea5455;
}
.theme-red .fc button.fc-state-active {
  background-color: #ea5455;
  color: #fff;
}
.theme-red .weather ul li {
  border: 2px solid #ea5455;
  color: #ea5455;
}
.theme-red
  .card-chat
  .chat-content
  .chat-item.chat-right
  .chat-details
  .chat-text {
  background-color: #ea5455;
  color: #fff;
}
.theme-red .nav-tabs .nav-item .nav-link {
  color: #ea5455;
}
.theme-red .swal-button.swal-button--confirm {
  background-color: #ea5455;
}
.theme-red .btn-group .btn.active {
  background-color: #ea5455;
  color: #fff;
}
.theme-red .media .media-right {
  color: #ea5455;
}
.theme-red .selectric-items li.selected {
  background-color: #ea5455;
  color: #fff;
}
.theme-red .selectric-items li.highlighted {
  background-color: #ea5455;
  color: #fff;
}
.theme-red .accordion .accordion-header[aria-expanded="true"] {
  background-color: #ea5455;
  color: #fff;
}
.theme-red .bootstrap-tagsinput .tag {
  background-color: #ea5455;
}
.theme-red
  body:not(.sidebar-mini)
  .sidebar-style-2
  .sidebar-menu
  > li.active
  > a:before {
  background-color: #ea5455;
}
.theme-red body.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  -webkit-box-shadow: 0 4px 8px #ef6d6e;
  box-shadow: 0 4px 8px #ef6d6e;
  background-color: #ea5455;
  color: #fff;
}
.theme-red
  body.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a {
  color: #ea5455;
}
.theme-red .activities .activity:before {
  background-color: #ea5455;
}
.theme-red .settingSidebar .settingPanelToggle {
  background: #ea5455;
}
.theme-red .settingSidebar .settingPanelToggle i {
  color: #fff;
}
.theme-red .sidebar-color .selectgroup-input:checked + .selectgroup-button {
  background-color: #ea5455;
}
.theme-red .custom-switch-input:checked ~ .custom-switch-indicator {
  background: #ea5455;
}
.theme-red .selectgroup-input:focus + .selectgroup-button,
.theme-red .selectgroup-input:checked + .selectgroup-button {
  background-color: #ea5455;
}
.theme-orange .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-orange .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-orange .show > .btn-outline-primary.dropdown-toggle,
.theme-cyan .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-cyan .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-cyan .show > .btn-outline-primary.dropdown-toggle,
.theme-green .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-green .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-green .show > .btn-outline-primary.dropdown-toggle,
.theme-red .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-red .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-red .show > .btn-outline-primary.dropdown-toggle,
.theme-white .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-white .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-white .show > .btn-outline-primary.dropdown-toggle,
.theme-black .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-black .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-black .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #ffa117;
  border-color: #ffa117;
}
.theme-orange.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  background-color: rgba(0, 0, 0, 0.14);
}
.theme-orange.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a:hover {
  color: #ffa117;
}
.theme-orange .bg-primary {
  background-color: #ffa117 !important;
}
.theme-orange .text-primary {
  color: #ffa117 !important;
}
.theme-orange a {
  color: #ffa117;
}
.theme-orange a:hover {
  color: #efb45f;
}
.theme-orange .btn-primary {
  background-color: #ffa117;
  border-color: transparent !important;
  color: #fff;
}
.theme-orange .btn-primary:focus {
  background-color: #efb45f !important;
}
.theme-orange .btn-primary:focus:active {
  background-color: #efb45f !important;
}
.theme-orange .btn-primary:active {
  background-color: #efb45f !important;
}
.theme-orange .btn-primary:hover {
  background-color: #efb45f !important;
  color: #fff;
}
.theme-orange .btn-primary.disabled {
  background-color: #ffa117;
  border-color: #ffa117;
}
.theme-orange .btn-primary:disabled {
  background-color: #ffa117;
  border-color: #ffa117;
}
.theme-orange .btn-outline-primary {
  color: #ffa117;
  background-color: transparent;
  background-image: none;
  border-color: #ffa117;
}
.theme-orange .btn-outline-primary:hover {
  color: #fff;
  background-color: #ffa117;
  border-color: #ffa117;
}
.theme-orange .btn-outline-primary.disabled {
  color: #ffa117;
  background-color: transparent;
}
.theme-orange .btn-outline-primary:disabled {
  color: #ffa117;
  background-color: transparent;
}
.theme-orange .btn-link {
  font-weight: 400;
  color: #ffa117;
  background-color: transparent;
}
.theme-orange .btn-link:hover {
  color: #efb45f;
}
.theme-orange .dropdown-item.active {
  color: #fff;
  background-color: #ffa117;
}
.theme-orange .dropdown-item:active {
  color: #fff;
  background-color: #ffa117;
}
.theme-orange .nav-pills .nav-link.active {
  color: #fff;
  background-color: #ffa117;
}
.theme-orange .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ffa117;
}
.theme-orange .page-link {
  color: #ffa117;
  background-color: #fff;
  border: 1px solid #ededed;
}
.theme-orange .page-link:focus {
  color: #efb45f;
}
.theme-orange .page-link:hover {
  color: #efb45f;
  background-color: #eaeaea;
}
.theme-orange .page-item .page-link {
  color: #ffa117;
}
.theme-orange .page-item.active .page-link {
  color: #fff;
  background-color: #ffa117;
  border-color: #ffa117;
}
.theme-orange .page-item.disabled .page-link {
  color: #ffa117;
}
.theme-orange .progress-bar {
  color: #fff;
  background-color: #ffa117;
}
.theme-orange .border-primary {
  border-color: #ffa117 !important;
}
.theme-orange .navbar-bg {
  background-color: #ffa117;
}
.theme-orange .jqvmap-circle {
  background-color: #ffa117;
  border: 1px solid #000;
}
.theme-orange .dropzone {
  border: 2px dashed #ffa117;
}
.theme-orange .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffa117;
}
.theme-orange
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #ffa117;
}
.theme-orange
  .custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: #ffa117;
}
.theme-orange
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #ffa117;
}
.theme-orange .list-group-item.active {
  color: #fff;
  background-color: #ffa117;
  border-color: #ffa117;
}
.theme-orange .navbar.active {
  background-color: #ffa117;
}
.theme-orange .form-control:focus {
  border-color: #ffa117;
}
.theme-orange .alert.alert-primary {
  background-color: #ffa117;
}
.theme-orange .card.card-primary {
  border-top: 2px solid #ffa117;
}
.theme-orange .fc button.fc-state-active {
  background-color: #ffa117;
  color: #fff;
}
.theme-orange .weather ul li {
  border: 2px solid #ffa117;
  color: #ffa117;
}
.theme-orange
  .card-chat
  .chat-content
  .chat-item.chat-right
  .chat-details
  .chat-text {
  background-color: #ffa117;
  color: #fff;
}
.theme-orange .nav-tabs .nav-item .nav-link {
  color: #ffa117;
}
.theme-orange .swal-button.swal-button--confirm {
  background-color: #ffa117;
}
.theme-orange .btn-group .btn.active {
  background-color: #ffa117;
  color: #fff;
}
.theme-orange .media .media-right {
  color: #ffa117;
}
.theme-orange .selectric-items li.selected {
  background-color: #ffa117;
  color: #fff;
}
.theme-orange .selectric-items li.highlighted {
  background-color: #ffa117;
  color: #fff;
}
.theme-orange .accordion .accordion-header[aria-expanded="true"] {
  background-color: #ffa117;
  color: #fff;
}
.theme-orange .bootstrap-tagsinput .tag {
  background-color: #ffa117;
}
.theme-orange
  body:not(.sidebar-mini)
  .sidebar-style-2
  .sidebar-menu
  > li.active
  > a:before {
  background-color: #ffa117;
}
.theme-orange body.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  -webkit-box-shadow: 0 4px 8px #f1a535;
  box-shadow: 0 4px 8px #f1a535;
  background-color: #ffa117;
  color: #fff;
}
.theme-orange
  body.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a {
  color: #ffa117;
}
.theme-orange .activities .activity:before {
  background-color: #ffa117;
}
.theme-orange .settingSidebar .settingPanelToggle {
  background: #ffa117;
}
.theme-orange .settingSidebar .settingPanelToggle i {
  color: #fff;
}
.theme-orange .sidebar-color .selectgroup-input:checked + .selectgroup-button {
  background-color: #ffa117;
}
.theme-orange .custom-switch-input:checked ~ .custom-switch-indicator {
  background: #ffa117;
}
.theme-orange .selectgroup-input:focus + .selectgroup-button,
.theme-orange .selectgroup-input:checked + .selectgroup-button {
  background-color: #ffa117;
}
.theme-orange .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-orange .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-orange .show > .btn-outline-primary.dropdown-toggle,
.theme-cyan .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-cyan .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-cyan .show > .btn-outline-primary.dropdown-toggle,
.theme-green .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-green .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-green .show > .btn-outline-primary.dropdown-toggle,
.theme-red .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-red .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-red .show > .btn-outline-primary.dropdown-toggle,
.theme-white .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-white .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-white .show > .btn-outline-primary.dropdown-toggle,
.theme-black .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-black .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-black .show > .btn-outline-primary.dropdown-toggle {
  color: #000;
  background-color: #6777ef;
  border-color: #6777ef;
}
.theme-white.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  background-color: rgba(0, 0, 0, 0.14);
}
.theme-white.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a:hover {
  color: #6777ef;
}
.theme-white .bg-primary {
  background-color: #6777ef !important;
}
.theme-white .text-primary {
  color: #fff !important;
}
.theme-white a:hover {
  color: #6777ef;
}
.theme-white .btn-primary {
  background-color: #6777ef;
  border-color: transparent !important;
  color: #fff;
}
.theme-white .btn-primary:focus {
  background-color: #4f5ece !important;
}
.theme-white .btn-primary:focus:active {
  background-color: #4f5ece !important;
}
.theme-white .btn-primary:active {
  background-color: #4f5ece !important;
}
.theme-white .btn-primary:hover {
  background-color: #4f5ece !important;
  color: #fff;
}
.theme-white .btn-primary.disabled {
  background-color: #6777ef;
  border-color: #6777ef;
}
.theme-white .btn-primary:disabled {
  background-color: #6777ef;
  border-color: #6777ef;
}
.theme-white .btn-outline-primary {
  color: #6777ef;
  background-color: transparent;
  background-image: none;
  border-color: #6777ef;
}
.theme-white .btn-outline-primary:focus {
  background-color: #4f5ece !important;
  color: #fff;
}
.theme-white .btn-outline-primary:focus:active {
  background-color: #4f5ece !important;
  color: #fff;
}
.theme-white .btn-outline-primary:hover {
  color: #fff;
  background-color: #6777ef;
  border-color: #6777ef;
}
.theme-white .btn-outline-primary.disabled {
  color: #6777ef;
  background-color: transparent;
}
.theme-white .btn-outline-primary:disabled {
  color: #6777ef;
  background-color: transparent;
}
.theme-white .btn-link {
  font-weight: 400;
  color: #6777ef;
  background-color: transparent;
}
.theme-white .btn-link:hover {
  color: #6777ef;
}
.theme-white .dropdown-item.active {
  color: #fff;
  background-color: #6777ef;
}
.theme-white .dropdown-item:active {
  color: #fff;
  background-color: #6777ef;
}
.theme-white .nav-pills .nav-link.active {
  color: #fff;
  background-color: #6777ef;
}
.theme-white .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #6777ef;
}
.theme-white .page-link {
  color: #6777ef;
  background-color: #fff;
  border: 1px solid #ededed;
}
.theme-white .page-link:focus {
  color: #6777ef;
}
.theme-white .page-link:hover {
  color: #6777ef;
  background-color: #eaeaea;
}
.theme-white .page-item .page-link {
  color: #6777ef;
}
.theme-white .page-item.active .page-link {
  color: #fff;
  background-color: #6777ef;
  border-color: #6777ef;
}
.theme-white .page-item.disabled .page-link {
  color: #6777ef;
}
.theme-white .progress-bar {
  color: #fff;
  background-color: #6777ef;
}
.theme-white .border-primary {
  border-color: #fff !important;
}
.theme-white .navbar-bg {
  background-color: #fff;
  -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}
.theme-white .jqvmap-circle {
  background-color: #6777ef;
  border: 1px solid #000;
}
.theme-white .dropzone {
  border: 2px dashed #6777ef;
}
.theme-white .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #6777ef;
}
.theme-white
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #6777ef;
}
.theme-white
  .custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: #6777ef;
}
.theme-white
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #6777ef;
}
.theme-white .list-group-item.active {
  color: #fff;
  background-color: #6777ef;
  border-color: #6777ef;
}
.theme-white .navbar.active {
  background-color: #6777ef;
}
.theme-white .form-control:focus {
  border-color: #6777ef;
}
.theme-white .alert.alert-primary {
  background-color: #6777ef;
}
.theme-white .card.card-primary {
  border-top: 2px solid #6777ef;
}
.theme-white .fc button.fc-state-active {
  background-color: #6777ef;
  color: #fff;
}
.theme-white .weather ul li {
  border: 2px solid #6777ef;
  color: #6777ef;
}
.theme-white
  .card-chat
  .chat-content
  .chat-item.chat-right
  .chat-details
  .chat-text {
  background-color: #6777ef;
  color: #fff;
}
.theme-white .nav-tabs .nav-item .nav-link {
  color: #6777ef;
}
.theme-white .swal-button.swal-button--confirm {
  background-color: #6777ef;
}
.theme-white .btn-group .btn.active {
  background-color: #6777ef;
  color: #fff;
}
.theme-white .media .media-right {
  color: #6777ef;
}
.theme-white .selectric-items li.selected {
  background-color: #6777ef;
  color: #fff;
}
.theme-white .selectric-items li.highlighted {
  background-color: #6777ef;
  color: #fff;
}
.theme-white .accordion .accordion-header[aria-expanded="true"] {
  background-color: #6777ef;
  color: #fff;
}
.theme-white .bootstrap-tagsinput .tag {
  background-color: #6777ef;
}
.theme-white
  body:not(.sidebar-mini)
  .sidebar-style-2
  .sidebar-menu
  > li.active
  > a:before {
  background-color: #6777ef;
}
.theme-white body.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  -webkit-box-shadow: 0 4px 8px #6777ef;
  box-shadow: 0 4px 8px #6777ef;
  background-color: #6777ef;
  color: #fff;
}
.theme-white
  body.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a {
  color: #6777ef;
}
.theme-white .activities .activity:before {
  background-color: #6777ef;
}
.theme-white .settingSidebar .settingPanelToggle {
  background: #6777ef;
}
.theme-white .settingSidebar .settingPanelToggle i {
  color: #fff;
}
.theme-white .settingSidebar ul.choose-theme li.active div::after {
  color: #000;
}
.theme-white .sidebar-color .selectgroup-input:checked + .selectgroup-button {
  background-color: #6777ef;
}
.theme-white .navbar .nav-link {
  color: #000;
}
.theme-white .navbar .form-inline .form-control {
  background-color: #e9ecef;
}
.theme-white .navbar .form-inline .form-control:focus {
  border-color: transparent;
}
.theme-white .navbar .form-inline .btn {
  background-color: #e9ecef;
}
.theme-white .custom-switch-input:checked ~ .custom-switch-indicator {
  background: #6777ef;
}
.theme-orange .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-orange .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-orange .show > .btn-outline-primary.dropdown-toggle,
.theme-cyan .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-cyan .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-cyan .show > .btn-outline-primary.dropdown-toggle,
.theme-green .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-green .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-green .show > .btn-outline-primary.dropdown-toggle,
.theme-red .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-red .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-red .show > .btn-outline-primary.dropdown-toggle,
.theme-white .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-white .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-white .show > .btn-outline-primary.dropdown-toggle,
.theme-black .btn-outline-primary:not([disabled]):not(.disabled):active,
.theme-black .btn-outline-primary.active:not([disabled]):not(.disabled),
.theme-black .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #353c48;
  border-color: #353c48;
}
.theme-black.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  background-color: rgba(0, 0, 0, 0.14);
}
.theme-black .bg-primary {
  background-color: #353c48 !important;
}
.theme-black .text-primary {
  color: #fff !important;
}
.theme-black a {
  color: #96a2b4;
}
.theme-black a:hover {
  color: #96a2b4;
  text-decoration: none;
}
.theme-black .btn-primary {
  background-color: #30353e;
  border-color: transparent !important;
  color: #fff;
}
.theme-black .btn-primary:focus {
  background-color: #34373e !important;
}
.theme-black .btn-primary:focus:active {
  background-color: #34373e !important;
}
.theme-black .btn-primary:active {
  background-color: #34373e !important;
}
.theme-black .btn-primary:hover {
  background-color: #34373e !important;
  color: #fff;
}
.theme-black .btn-primary.disabled {
  background-color: #353c48;
  border-color: #353c48;
}
.theme-black .btn-primary:disabled {
  background-color: #353c48;
  border-color: #353c48;
}
.theme-black .btn-outline-primary {
  color: #353c48;
  background-color: transparent;
  background-image: none;
  border-color: #353c48;
}
.theme-black .btn-outline-primary:hover {
  color: #fff;
  background-color: #353c48;
  border-color: #353c48;
}
.theme-black .btn-outline-primary.disabled {
  color: #353c48;
  background-color: transparent;
}
.theme-black .btn-outline-primary:disabled {
  color: #353c48;
  background-color: transparent;
}
.theme-black .btn-link {
  font-weight: 400;
  color: #353c48;
  background-color: transparent;
}
.theme-black .btn-link:hover {
  color: #353c48;
}
.theme-black .dropdown-item.active {
  color: #fff;
  background-color: #353c48;
}
.theme-black .dropdown-item:active {
  color: #fff;
  background-color: #353c48;
}
.theme-black .nav-pills .nav-link.active {
  color: #fff;
  background-color: #353c48;
}
.theme-black .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #353c48;
}
.theme-black .page-link {
  color: #353c48;
  background-color: #fff;
  border: 1px solid #ededed;
}
.theme-black .page-link:focus {
  color: #353c48;
}
.theme-black .page-link:hover {
  color: #353c48;
  background-color: #eaeaea;
}
.theme-black .page-item .page-link {
  color: #353c48;
}
.theme-black .page-item.active .page-link {
  color: #fff;
  background-color: #353c48;
  border-color: #353c48;
}
.theme-black .page-item.disabled .page-link {
  color: #353c48;
}
.theme-black .progress-bar {
  color: #fff;
  background-color: #353c48;
}
.theme-black .border-primary {
  border-color: #353c48 !important;
}
.theme-black .navbar-bg {
  background-color: #353c48;
}
.theme-black .jqvmap-circle {
  background-color: #353c48;
  border: 1px solid #000;
}
.theme-black .dropzone {
  border: 2px dashed #353c48;
}
.theme-black .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #353c48;
}
.theme-black
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #353c48;
}
.theme-black
  .custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: #353c48;
}
.theme-black
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #353c48;
}
.theme-black .list-group-item.active {
  color: #fff;
  background-color: #353c48;
  border-color: #353c48;
}
.theme-black .navbar.active {
  background-color: #353c48;
}
.theme-black .form-control:focus {
  border-color: #353c48;
}
.theme-black .alert.alert-primary {
  background-color: #353c48;
}
.theme-black .card.card-primary {
  border-top: 2px solid #353c48;
}
.theme-black .fc button.fc-state-active {
  background-color: #353c48;
  color: #fff;
}
.theme-black .weather ul li {
  border: 2px solid #353c48;
  color: #353c48;
}
.theme-black
  .card-chat
  .chat-content
  .chat-item.chat-right
  .chat-details
  .chat-text {
  background-color: #353c48;
  color: #fff;
}
.theme-black .nav-tabs .nav-item .nav-link {
  color: #353c48;
}
.theme-black .swal-button.swal-button--confirm {
  background-color: #353c48;
}
.theme-black .btn-group .btn.active {
  background-color: #353c48;
  color: #fff;
}
.theme-black .media .media-right {
  color: #353c48;
}
.theme-black .selectric-items li.selected {
  background-color: #353c48;
  color: #fff;
}
.theme-black .selectric-items li.highlighted {
  background-color: #353c48;
  color: #fff;
}
.theme-black .accordion .accordion-header[aria-expanded="true"] {
  background-color: #353c48;
  color: #fff;
}
.theme-black .bootstrap-tagsinput .tag {
  background-color: #353c48;
}
.theme-black
  body:not(.sidebar-mini)
  .sidebar-style-2
  .sidebar-menu
  > li.active
  > a:before {
  background-color: #353c48;
}
.theme-black body.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  -webkit-box-shadow: 0 4px 8px #353c48;
  box-shadow: 0 4px 8px #353c48;
  background-color: #353c48;
  color: #fff;
}
.theme-black
  body.sidebar-mini
  .main-sidebar
  .sidebar-menu
  > li
  ul.dropdown-menu
  li.active
  > a {
  color: #353c48;
}
.theme-black .activities .activity:before {
  background-color: #353c48;
}
.theme-black .settingSidebar .settingPanelToggle {
  background: #353c48;
}
.theme-black .settingSidebar .settingPanelToggle i {
  color: #fff;
}
.theme-black .sidebar-color .selectgroup-input:checked + .selectgroup-button {
  background-color: #353c48;
}
.theme-black .custom-switch-input:checked ~ .custom-switch-indicator {
  background: #353c48;
}
.theme-black .selectgroup-input:focus + .selectgroup-button,
.theme-black .selectgroup-input:checked + .selectgroup-button {
  background-color: #353c48;
}
.dark .custom-select,
.dark .custom-file-label,
.dark .select2-container .select2-selection--multiple,
.dark .select2-container .select2-selection--single {
  background-color: #353c48;
  border-color: #979da6;
  color: #96a2b4;
}
.dark {
  background-color: #3b4452;
}
.dark .card {
  background-color: #353c48;
  color: #96a2b4;
}
.dark .card .card-header {
  border-bottom-color: #353c48;
}
.dark .card .card-header h4 + .card-header-action .btn {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dark .card .card-header h4 + .card-header-action .btn.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}
.dark .card.card-statistic-1 .card-body {
  color: #96a2b4;
}
.dark .card.card-statistic-2 .card-body {
  color: #96a2b4;
}
.dark .section .section-header h1 {
  color: #96a2b4;
}
.dark .section .section-header .section-header-breadcrumb {
  background: #353c48;
}
.dark .section .section-title {
  color: #96a2b4;
}
.dark .navbar.active {
  background-color: #fff;
}
.dark .navbar .form-inline .form-control {
  background-color: #f2f2f2;
}
.dark .navbar .form-inline .btn {
  background-color: #f2f2f2;
}
.dark .table {
  color: #96a2b4;
}
.dark .table.table-bordered td {
  border-color: #666869;
}
.dark .table.table-bordered th {
  border-color: #666869;
}
.dark .table:not(.table-sm) thead th {
  color: #96a2b4;
  background-color: rgba(0, 0, 0, 0.2);
}
.dark .btn-primary {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dark .btn-secondary {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dark .btn-info {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dark .btn-warning {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dark .btn-danger {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dark .btn-success {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dark .btn-light {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dark .btn-dark {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dark .text-title {
  color: #96a2b4;
}
.dark .main-footer {
  border-top: 1px solid #353c48;
  background: #353c48;
}
.dark .btn-outline-primary {
  color: #96a2b4;
  border-color: #96a2b4;
}
.dark .form-control {
  background-color: #353c48;
  border-color: #979da6;
  color: #96a2b4;
}
.dark .select2-container.select2-container--focus .select2-selection--multiple {
  background-color: #353c48;
  border-color: #979da6;
  color: #96a2b4;
}
.dark .select2-container.select2-container--open .select2-selection--single {
  background-color: #353c48;
  border-color: #979da6;
  color: #96a2b4;
}
.dark .selectric {
  background-color: #353c48;
  border-color: #979da6;
  color: #96a2b4;
}
.dark .selectric .label {
  color: #96a2b4;
}
.dark .selectric:hover {
  background-color: #353c48;
  border-color: #979da6;
  color: #96a2b4;
}
.dark
  .select2-container--default
  .select2-results__option[aria-selected="true"] {
  background-color: #32363c;
}
.dark
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #32363c;
}
.dark
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #96a2b4;
}
.dark
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  background-color: #353c48;
  color: #96a2b4;
}
.dark
  .select2-container--default
  .select2-search--inline
  .select2-search__field {
  color: #96a2b4;
}
.dark .select2-dropdown {
  background-color: #353c48;
  color: #96a2b4;
}
.dark .bootstrap-tagsinput {
  background-color: #353c48;
  border-color: #979da6;
  color: #96a2b4;
}
.dark .selectgroup-button {
  background-color: #353c48;
}
.dark .custom-switch-indicator {
  background: #585b5f;
}
.dark .selectric-items {
  background-color: #353c48;
  -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.3);
  color: #96a2b4;
}
.dark .selectric-items li {
  color: #96a2b4;
}
.dark .selectric-items li.selected {
  background-color: #33333a;
}
.dark .selectric-items li.highlighted {
  background-color: #33333a;
}
.dark .selectric-items li:hover {
  background-color: #37373e;
}
.dark .custom-switch-description {
  color: #96a2b4;
}
.dark .input-group-text {
  background-color: #32363c;
  color: #96a2b4;
}
.dark .custom-file-label::after {
  background-color: #32363c;
  color: #96a2b4;
}
.dark .jumbotron {
  background-color: #32363c;
}
.dark .article .article-details {
  background-color: #353c48;
}
.dark .article.article-style-b .article-details p {
  color: #96a2b4;
}
.dark .article.article-style-c .article-details p {
  color: #96a2b4;
}
.dark .article.article-style-c .article-details .article-category {
  color: #96a2b4;
}
.dark .article.article-style-c .article-details .article-category a {
  color: #96a2b4;
}
.dark .text-job {
  color: #96a2b4;
}
.dark #mail-nav li a {
  color: #96a2b4;
}
.dark #mail-nav #mail-folders > li a:hover {
  background-color: #313131;
}
.dark #mail-nav #mail-labels li a:hover {
  background-color: #313131;
}
.dark #mail-nav #online-offline li a:hover {
  background-color: #313131;
}
.dark .breadcrumb {
  background-color: #353c48;
}
.dark .dropdown-menu {
  background-color: #353c48;
  -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.3);
}
.dark .dropdown-menu a:hover {
  color: #fff;
  background-color: #32363c;
}
.dark .dropdown-menu .dropdown-title {
  color: #fff !important;
}
.dark .dropdown-item {
  color: #96a2b4;
  background-color: #353c48;
}
.dark .dropdown-divider {
  border-top-color: #96a2b4;
}
.dark .dropdown-list .dropdown-item {
  border-bottom: 1px solid #96a2b4;
}
.dark .dropdown-list .dropdown-item .dropdown-item-desc {
  color: #96a2b4;
}
.dark .dropdown-list .dropdown-item .dropdown-item-desc b {
  color: #fff;
}
.dark .dropdown-list .dropdown-item.dropdown-item-unread {
  background-color: #32363c;
}
.dark .dropdown-list .dropdown-list-content:not(.is-end):after {
  background-image: none;
}
.dark
  .dropdown-list
  .dropdown-list-message
  .dropdown-item
  .dropdown-item-desc
  .message-user {
  color: #96a2b4;
}
.dark
  .dropdown-list
  .dropdown-list-message
  .dropdown-item
  .dropdown-item-desc
  .messege-text {
  color: #96a2b4;
}
.dark .list-group-item {
  background-color: #353c48;
  border: 1px solid rgba(234, 227, 227, 0.2);
}
.dark .list-group-item.disabled {
  background-color: #343a40;
}
.dark .list-group-item-action {
  color: #96a2b4;
}
.dark .dropzone {
  border: 2px dashed #96a2b4;
  background: #353c48;
}
.dark .dropzone .dz-message {
  color: #96a2b4;
}
.dark .pricing {
  background: #353c48;
}
.dark .pricing .pricing-cta a {
  background-color: #32363c;
}
.dark .settingSidebar .settingSidebar-body {
  background: #353c48;
  color: #96a2b4;
}
.dark .settingSidebar .setting-panel-header {
  background-color: #32363c;
  color: #96a2b4;
  border: 1px solid #32363c;
}
.dark .image-preview {
  background-color: #353c48;
}
.dark .invoice {
  background-color: #353c48;
}
.dark .invoice .invoice-detail-item .invoice-detail-value {
  color: #fff;
}
.dark .main-wrapper-1 .section .section-header {
  border-top: 1px solid #38424b;
}
.dark .list-unstyled-border li {
  border-bottom: 1px solid #616161;
}
.dark .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.06);
}
.dark table.dataTable thead th {
  border-bottom: 1px solid #343b44 !important;
}
.dark table.dataTable thead td {
  border-bottom: 1px solid #343b44 !important;
}
.dark .media .media-title {
  color: #96a2b4;
}
.dark .media .media-title a {
  color: #96a2b4;
}
.dark.main-sidebar .sidebar-menu li ul.dropdown-menu li.active > a {
  color: #f1d065;
}
.dark.main-sidebar .sidebar-menu li ul.dropdown-menu li.active > a:before {
  color: #f1d065;
}
.dark.main-sidebar .sidebar-menu li ul.dropdown-menu li a:hover {
  color: #f1d065;
}
.dark.main-sidebar .sidebar-menu li ul.dropdown-menu li a:hover:before {
  color: #f1d065;
}
.dark
  .profile-widget
  .profile-widget-items
  .profile-widget-item
  .profile-widget-item-label {
  color: #96a2b4;
}
.dark
  .profile-widget
  .profile-widget-items
  .profile-widget-item
  .profile-widget-item-value {
  color: #96a2b4;
}
.dark .user-item .user-details .user-name {
  color: #96a2b4;
}
.dark .gradient-bottom:after {
  background-image: none;
}
.dark .buttons .btn {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dark .btn-group > .btn {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dark .btn-group-vertical > .btn {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dark .chat-box .chat-content {
  background-color: #353c48 !important;
}
.dark .people-list .chat-list li.active {
  background: #1f1e1e;
}
.dark .people-list .chat-list li:hover {
  background: #1f1e1e;
}
.dark .custom-switch-input:checked ~ .custom-switch-description {
  color: #f5f7f9;
}
.dark .form-group > label {
  color: #96a2b4;
}
.dark input.form-control {
  color: #96a2b4;
}
.dark .fc-view > table td {
  color: #fff;
}
.dark .max-texts a {
  color: #96a2b4;
}
.dark .table-hover tbody tr:hover {
  color: #96a2b4;
}
.dark .author-box .author-box-job {
  color: #96a2b4;
}
.dark .wizard > .steps .disabled a {
  background: #32363c;
  color: #96a2b4;
}
.dark .activities .activity .activity-detail {
  background-color: #353c48;
}
.dark .statistic-details .statistic-details-item .detail-name {
  color: #96a2b4;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #4f5ece;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f8b600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#grad {
  background-image: linear-gradient(to left, #346599, #3a9bc1);
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 200px;
  margin: 0px auto 20px;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 236px;
  font-weight: 200;
  margin: 0px;
  color: #211b19;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound .notfound-404 h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
  color: #211b19;
  background: #fff;
  padding: 10px 5px;
  margin: auto;
  display: inline-block;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
}

.notfound a {
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  padding: 13px 23px;
  background: #ff6300;
  font-size: 18px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  color: #ff6300;
  background: #211b19;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 h1 {
    font-size: 148px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 148px;
    margin: 0px auto 10px;
  }
  .notfound .notfound-404 h1 {
    font-size: 86px;
  }
  .notfound .notfound-404 h2 {
    font-size: 16px;
  }
  .notfound a {
    padding: 7px 15px;
    font-size: 14px;
  }
}
