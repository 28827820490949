.table-report thead th {
  text-align: center;
  width: 229px;
}

.table-report td {
  text-align: right;
  height: 10%;
  border-width: 1px;
  /* width: fit-content; */
}

.table-report {
  height: 10%;
  width: 100%;
}

.table-report > tbody > tr > th {
  width: 173px;
  max-width: 160px;
}

.table-report > tbody > tr > td {
  width: 173px;
  max-width: 160px;
}

.table-report caption {
  float: left;
  vertical-align: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  min-width: 10px;
  max-width: 34px;
}

.title-table {
  width: 5px !important;
  background-color: lightgrey;
  vertical-align: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.date-th {
  width: 178px;
  max-width: 178px;
  text-align: center;
}

.table-dates {
  text-align: center;
}

@media print {
  /* .table-report caption {
		float: left;
		vertical-align: center;
		writing-mode: vertical-rl;
		text-orientation: mixed;
		min-width: 10px;
		max-width: 34px;
	} */

  .table {
    font-family: Roboto, sans-serif;
    font-size: small;
    border: 2px solid #000 !important;
    --webkit-print-color-adjust: exact;
  }

  .table tr {
    font-family: Roboto, sans-serif;
    font-size: small;
    border: 0.7px solid #000 !important;
    --webkit-print-color-adjust: exact;
  }
  .table td {
    font-family: Roboto, sans-serif;
    font-size: small;
    border: 1px solid lightgray !important;
    color: #000 !important;
    /* min-width: 229px; */
    --webkit-print-color-adjust: exact;
    height: 10%;
  }
  .table th {
    font-family: Roboto, sans-serif;
    border: 1px solid lightgray !important;
    color: #000 !important;
    --webkit-print-color-adjust: exact;
  }
  table {
    page-break-inside: avoid;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }
}
